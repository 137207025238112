import {useQuery} from "@apollo/client";
import {DataTable} from "../components/Table/DataTable";
import {FlagButton} from "../components/Input/FlagButton";
import {Link} from "react-router-dom";
import {ConfirmHandlingEditorInput, GetAssignedArticlesDocument, UserAction} from "../generated/graphql";
import {useAuth} from "../hooks/useAuth";
import {formatStatus} from "utilities/formatters";
import {Button} from "antd";
import {useConfirmHandlingEditor} from "hooks/useConfirmHandlingEditor";
import {useState} from "react";
import {DialogButton} from "components/Modal/DialogButton";
import {TextInputDialog} from "routes/Dialogs/TextInputDialog";

export const Assigned = () => {
    const { data, loading, refetch } = useQuery(GetAssignedArticlesDocument);
    const { can } = useAuth();
    const [isConfirming, setIsConfirming] = useState(false);

    const mutation = useConfirmHandlingEditor();
    const confirm = async (input: ConfirmHandlingEditorInput) => {
        setIsConfirming(true);
        await mutation(input);
        await refetch();
        setIsConfirming(false);
    }

    const isEditor = can(UserAction.ManageArticles);

    return <>
        { loading && <i>Loading...</i> }
        { data && <DataTable source={data.assignedArticles} columns={[
            {
                key: 'Flagged',
                header: <></>,
                value: a => a.isFlagged ? 'Flagged' : 'Not flagged',
                filter: true,
                render: a => <FlagButton article={a} />
            },
            {
                key: 'Number',
                value: a => a.number,
                render: a => <>{a.number}</>
            },
            {
                key: isEditor ? 'Handling editor' : 'Managing editor',
                value: a => (isEditor ? a.handlingEditor : a.managingEditor)?.displayName,
                filter: true
            },
            {
                key: 'Title',
                value: a => a.title,
                render: a => <Link to={`./article/${a.number}`}>{a.title}</Link>
            },
            {
                key: 'Authors',
                value: a => a.authors.map(p => p.displayName).join(', ')
            },
            {
                key: 'Status',
                value: a => formatStatus(a.status)
            },
            {
                key: '',
                render: a => <>{ !isEditor && !a.handlingEditorAccepted && <>
                    <Button type="link" disabled={isConfirming}
                            onClick={() => confirm({ articleId: a.id, accept: true })}>
                        Accept
                    </Button>
                    <DialogButton disabled={isConfirming}
                                  dialog={props => <TextInputDialog
                                    title="Decline to handle submission"
                                    text="Please could you let us know why; for example, the topic is
not a good match for your interests, you are too busy right now or you
feel the paper is highly likely to be rejected (lowest 5%). In the first
two cases, we would appreciate it if you have any suggestions for
another editor."
                                    {...props} onOk={comment => confirm({ articleId: a.id, accept: false, comment })} />}>
                        Decline
                    </DialogButton>
                </> }</>
            }
        ]} /> }
    </>
}