import {useQuery, useReactiveVar} from "@apollo/client";
import {GetActionsDocument, UserAction} from "generated/graphql";
import {userVar} from "utilities/auth";
import {useEffect} from "react";

export const useAuth = () => {
  const { data, refetch } = useQuery(GetActionsDocument);

  const actions = data?.actions ?? [];
  const can = (action: UserAction) => actions.includes(action);

  const user = useReactiveVar(userVar);

  useEffect(() => { refetch(); }, [refetch, user?.token]);

  return { user: user?.displayName ? user : null, can };
}