import {
  ArticleStatus,
  ArticleWithRefereesFragment,
  CustomList,
  GetArticlesWithRefereesDocument,
  RefereeRequestStatus,
  RefereeRequestType
} from "generated/graphql";
import {CsvExport, DataTable} from "components/Table/DataTable";
import {useMemo, useState} from "react";
import {Column} from "components/Table/Column";
import {useQuery} from "@apollo/client";
import {Link} from "react-router-dom";
import {formatDate} from "utilities/formatters";
import {Checkbox} from "antd";
import {VerticalSpace} from "components/Layout/VerticalSpace";
import dayjs from "dayjs";
import {FlagButton} from "components/Input/FlagButton";
import {useJournal} from "hooks/useJournal";
import {setArticleOrder} from "routes/Editor/ArticleList";

interface Props {
  custom?: CustomList
}


export const ArticleListWithReferees = ({ custom }: Props) => {
  const [showTitle, setShowTitle] = useState(false);
  const { data, loading } = useQuery(GetArticlesWithRefereesDocument,
    { variables: { input: custom ? { customList: custom } : { statuses: [ArticleStatus.RequestSent] } } });
  const journal = useJournal();

  setArticleOrder(data?.articles.map(a => a.number) ?? []);

  const overdue = custom === CustomList.OverdueRefs;
  const columns: Column<ArticleWithRefereesFragment>[] = useMemo(() => {
    const targetStatus = overdue ? RefereeRequestStatus.Refereeing : RefereeRequestStatus.Sent;

    const getRequests = (a: ArticleWithRefereesFragment) =>
      a.refereeRequests.filter(r => r.status === targetStatus && (!overdue || dayjs(r.dateEnd) < dayjs()));
    const getRequest = (a: ArticleWithRefereesFragment) => getRequests(a)[0];

    const titleColumns: Column<ArticleWithRefereesFragment>[] = showTitle ? [{
      key: 'Title',
      value: a => a.title
    },
      {
        key: 'Authors',
        value: a => a.authors.map(p => p.displayName).join(', ')
      }] : [];

    const dateColumns : Column<ArticleWithRefereesFragment>[] = !overdue ?
      [{
        key: 'Days',
        value: a => getRequest(a)?.duration,
        render: a => <>{getRequests(a).map(r => <div>{r.duration}</div>)}</>
      },
        {
          key: 'Reply by',
          value: a => getRequest(a)?.dateRequested,
          render: a => <>{getRequests(a).map(r => <div>{r.dateRequested && formatDate(dayjs(r.dateRequested).add(
            r.type === RefereeRequestType.QuickOpinion
              ? journal.featureSettings.replyByDaysQuickOpinion
              : journal.featureSettings.replyByDaysFullReport, 'day'
          ))}</div>)}</>
        }]
      :
      [{
        key: 'Deadline',
        value: a => getRequest(a)?.dateEnd,
        render: a => <>{getRequests(a).map(r => <div>{formatDate(r.dateEnd)}</div>)}</>,
        type: "date"
      },
      {
        key: 'Days overdue',
        value: a => dayjs().diff(dayjs(getRequest(a)?.dateEnd), 'day'),
        render: a => <>{getRequests(a).map(r => <div>{dayjs().diff(dayjs(r.dateEnd), 'day')}</div>)}</>,
      }];

    return [
    {
      key: 'Flagged',
      header: <></>,
      value: a => a.isFlagged ? 'Flagged' : 'Not flagged',
      filter: true,
      render: a => <FlagButton article={a} />
    },
    {
      key: 'Number',
      value: a => a.number,
      render: a => <Link to={`/editor/article/${a.number}`}>{a.number}</Link>
    },
    {
      key: 'ME',
      value: a => a.managingEditor?.initials,
      filter: true
    },
    ...(journal.featureSettings.disableHandlingEditor ? [] : [{
      key: 'HE',
      value: (a: ArticleWithRefereesFragment) => a.handlingEditor?.initials,
      filter: true
    }]),
    ...titleColumns,
    {
      key: 'Referee',
      value: a => getRequest(a)?.referee.displayName,
      render: a => <>{getRequests(a).map(r => <div>{r.referee.displayName}</div>)}</>
    },
    {
      key: 'Date request',
      value: a => getRequest(a)?.dateRequested,
      render: a => <>{getRequests(a).map(r => <div>{formatDate(r.dateRequested)}</div>)}</>
    },
    ...dateColumns,
    overdue ? {
      key: 'Reminder sent (overdue)',
      value: a => getRequest(a)?.lastOverdueDate,
      render: a => <>{getRequests(a).map(r => <div>{formatDate(r.lastOverdueDate)}&nbsp;</div>)}</>
    } : {
      key: 'Reminder sent',
      value: a => getRequest(a)?.lastReminderDate,
      render: a => <>{getRequests(a).map(r => <div>{formatDate(r.lastReminderDate)}&nbsp;</div>)}</>
    }]
  }, [showTitle, overdue, journal.featureSettings.disableHandlingEditor,
    journal.featureSettings.replyByDaysQuickOpinion, journal.featureSettings.replyByDaysFullReport]);

  return <>
    { loading && <i>Loading...</i> }
    { data?.articles && <>
        <label>
          <Checkbox onChange={v => setShowTitle(v.target.checked)} value={showTitle} /> Show titles and authors
        </label>
        <VerticalSpace />
        <DataTable columns={columns} source={data.articles}
                   exports={[CsvExport(overdue ? "OVERDUE_REFS" : "REQUEST_SENT")]} />
    </>}
  </>
}