import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {setToken} from "utilities/auth";
import {useQuery} from "@apollo/client";
import {GetRefereeRequestByTokenDocument, RefereeRequestStatus} from "generated/graphql";
import {Button, Descriptions} from "antd";
import {formatDate} from "utilities/formatters";
import {useJournal} from "hooks/useJournal";
import {formatValue, isClosed} from "utilities/enumHelpers";
import {FileLink} from "components/Navigation/FileLink";
import {DialogButton} from "components/Modal/DialogButton";
import {RefereeReportDialog} from "routes/Editor/Dialogs/RefereeReportDialog";
import {FormDialog} from "routes/Dialogs/FormDialog";
import {TextInputDialog} from "routes/Dialogs/TextInputDialog";
import {useConfirmRefereeRequest} from "hooks/useConfirmRefereeRequest";

const { Item } = Descriptions;

export const Referee = () => {
  const { token } = useParams();
  const journal = useJournal();

  useEffect(() => { if (token) setToken(token) }, [token]);

  const { data, loading, refetch } = useQuery(GetRefereeRequestByTokenDocument, { skip: !token });

  const req = data?.refereeRequestByToken.request;

  const { confirm, isConfirming } = useConfirmRefereeRequest();

  return <>
    { loading && <i>Loading...</i> }
    { data && !req && <>Thank you for your response.</> }
    { req && <Descriptions bordered column={1} style={{ maxWidth: 800 }}>
        <Item label="Manuscript"><FileLink file={req.articleVersion.manuscript} text={`${journal.code} ${req.articleVersion.article.number}`} /></Item>
        <Item label="Title">{ req.articleVersion.article.title }</Item>
        <Item label="Authors">{ req.articleVersion.article.authors.map(a => a.displayName).join(', ') }</Item>
      { req.articleVersion.commentFile && <Item label="Additional file">
          <FileLink file={req.articleVersion.commentFile} />
      </Item> }
        <Item label="Referee">{ req.referee.displayName }</Item>
        <Item label="Date requested">{formatDate(req.dateRequested)}</Item>
        <Item label="Deadline">{formatDate(req.dateEnd)}</Item>
        <Item label="Type">{formatValue(req.type)}</Item>
        <Item label="Status">
          { req.status === RefereeRequestStatus.Sent &&
              <>
            <Button type='link' disabled={isConfirming} onClick={() => confirm(req, true)}>Agree to referee</Button>
            <DialogButton disabled={isConfirming}
                dialog={props => <TextInputDialog {...props}
              title="Decline to referee"
              text="Please could you let us know why; for example, the topic is
not a good match for your interests, you are too busy right now or you
feel the paper is highly likely to be rejected (lowest 5%). In the first
two cases, we would appreciate it if you have any suggestions for
another reviewer."
              onOk={c => confirm(req, false, c)}
            />}>Decline</DialogButton>
          </>}
          { req.status === RefereeRequestStatus.Refereeing && <>Agreed to referee</> }
          { req.status === RefereeRequestStatus.Closed && <>Declined to referee</> }
          { req.status === RefereeRequestStatus.Completed && <>Completed</> }
        </Item>
        <Item label="Report">
          {req.status === RefereeRequestStatus.Completed && <div>Thank you for submitting your report</div>}
          {req.report && <FileLink file={req.report} />}
          {!isClosed(req.status) && req.status !== RefereeRequestStatus.Completed && !req.formSubmissionId
            && <DialogButton type="link" dialog={props => <RefereeReportDialog {...props} refereeRequestId={req.id} />}>Submit report</DialogButton>}
          {!isClosed(req.status) && req.status !== RefereeRequestStatus.Completed && req.formSubmissionId
            && <DialogButton type="link" dialog={props => <FormDialog {...props} onComplete={refetch} submissionId={req.formSubmissionId ?? 0} />}>Submit report</DialogButton>}
        </Item>
    </Descriptions>  }
  </>
}