import {Column} from "components/Table/Column";
import {ArticleWithVersionFragment} from "generated/graphql";
import {FlagButton} from "components/Input/FlagButton";
import {Link} from "react-router-dom";
import {formatStatus} from "utilities/formatters";
import {useJournal} from "hooks/useJournal";
import {useAuth} from "hooks/useAuth";

const defaultColumns: Column<ArticleWithVersionFragment>[] = [
  {
    key: 'Flagged',
    header: <></>,
    value: a => a.isFlagged ? 'Flagged' : 'Not flagged',
    filter: true,
    render: a => <FlagButton article={a} />
  },
  {
    key: 'Number',
    value: a => a.number,
    render: a => <>{a.number}</>
  },
  {
    key: 'ME',
    value: a => a.managingEditor?.initials,
    filter: true
  },
  {
    key: 'HE',
    value: a => a.handlingEditor?.initials,
    filter: true
  },
  {
    key: 'Title',
    value: a => a.title,
    render: a => <Link to={`../article/${a.number}`}>{a.title}</Link>
  },
  {
    key: 'Authors',
    value: a => a.authors.map(p => p.displayName).join(', ')
  },
  {
    key: 'Page count',
    value: a => a.lastVersion.pageCount
  }];

export const useColumns = (hideStatus: boolean) => {
  const journal = useJournal();
  const user = useAuth();

  let columns = hideStatus ? defaultColumns : [...defaultColumns, {
    key: 'Status',
    value: (a: ArticleWithVersionFragment) => formatStatus(a.status),
  }];
  if (journal.featureSettings.disableHandlingEditor) {
    columns = columns.filter(c => c.key !== 'HE');
  }
  if (journal.featureSettings.hideFlagForEditors && user.user?.role !== "Secretary") {
    columns = columns.filter(c => c.key !== 'Flagged');
  }
  return columns;
}