import {Column} from "components/Table/Column";
import {Table} from "antd";
import {Children, cloneElement, ReactElement, useMemo, useState} from "react";
import {ColumnType} from "antd/es/table";
import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface Indexable {
  id: number;
  index: number;
}

interface Props<T extends Indexable> {
  source: T[];
  columns: Column<T>[];
  summary?: () => JSX.Element;
  rowKey?: string;
  onMove: (target: T, previous: T) => void;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
      /translate3d\(([^,]+),/,
      'translate3d(0,',
    ),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {Children.map(children, (child) => {
        if ((child as ReactElement).key === 'sort') {
          return cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

interface Sortable {
  id: number
  index: number
}

export function DragDataTable<T extends Indexable>({ source, columns, summary, onMove }: Props<T>) {
  const [dataSource, setDataSource] = useState(source.map(s => ({ id: s.id, index: s.index })));

  const getById = (id: number) => source.filter(s => s.id === id)[0];

  const mappedColumns = useMemo(() => columns.map<ColumnType<Sortable>>(c => {
    const val = c.value ?? (_ => "");
    return {
      title: c.header ?? c.key,
      key: c.key,
      render: c.render ? ((a: Sortable) => c.render!(getById(a.id)))
        : ((a: Sortable) => val(getById(a.id))?.toString()),
      width: c.width
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [columns]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      console.log(active, over);
      const current = dataSource.filter(t => t.index === active.id)[0];
      const moveTo = dataSource.filter(t => t.index === over?.id)[0];
      onMove(getById(current.id), getById(moveTo.id));
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.index === active.id);
        const overIndex = previous.findIndex((i) => i.index === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return <>
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        items={dataSource.map((i) => i.index)}
        strategy={verticalListSortingStrategy}
      >
        <Table dataSource={dataSource} rowKey="index"
               components={{ body: { row: Row }}}
               columns={[{ key: 'sort', width: 20 }, ...mappedColumns]}
               pagination={false}
               summary={summary}
               bordered
        />
      </SortableContext>
    </DndContext>
  </>
}