import {useMutation} from "@apollo/client";
import {AssignRefereeDocument, AssignRefereeInput} from "generated/graphql";

export const useAssignReferee = () => {
  const [mutate] = useMutation(AssignRefereeDocument, {
    update(cache, { data }) {
      const req = data?.assignReferee.refereeRequest;
      if (!req) return;
      cache.modify({
        id: `Article:${req.articleId}`,
        fields: {
          refereeRequests: (existing: readonly any[]) => [req, ...existing]
        }
      })
    }
  });

  return (input: AssignRefereeInput) => mutate({ variables: { input }});
}