import {FormAnswerInput, FormChoice, QuestionType} from "generated/graphql";
import {Input, Radio, Select} from "antd";
import TextArea from "antd/es/input/TextArea";

interface Props {
  question: { id: number, type: QuestionType, choices: FormChoice[] };
  value?: FormAnswerInput;
  onChange?: (val: FormAnswerInput) => void;
}

export const FormInputControl = ({ question, value, onChange } : Props) => {
  const change = (ans: { text?: string, choiceId?: number, boolean?: boolean }) =>
    onChange?.({ questionId: question.id, ...ans });
  switch (question.type) {
    case QuestionType.Choice:
      return <Select value={value?.choiceId}
                     onChange={v => change({choiceId: v})}
                     options={question.choices.map(c => ({ value: c.id, label: c.text }))} />
    case QuestionType.Open:
      return <TextArea value={value?.text ?? undefined} onChange={v => change({ text: v.currentTarget.value })} rows={3} />
    case QuestionType.YesNo:
      return <Radio.Group value={value?.boolean === undefined ? undefined : value?.boolean ? 1 : 0}
                          onChange={v => change({ boolean: v.target.value === 1 })}>
        <Radio value={1}>Yes</Radio>
        <Radio value={0}>No</Radio>
      </Radio.Group>
  }
  return <Input />
}