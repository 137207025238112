import {Button, List} from "antd";
import {DialogButton} from "components/Modal/DialogButton";
import {DialogProps} from "components/Modal/DialogProps";
import {DeleteOutlined} from "@ant-design/icons";
import {CSSProperties} from "react";

interface Props {
  value?: any[]
  onChange?: (collection: any[]) => void
  format: (o: any) => string
  addDialog: (props: DialogProps & { onOk: (o: any) => void, initial?: any }) => JSX.Element,
  initial?: any
  style?: CSSProperties
}

export const CollectionEditor = ( { value, onChange, format, addDialog, initial, style }: Props ) => {
  const collection = value ?? [];
  const add = (obj: any) => {
    onChange?.([...collection, obj]);
  }

  const remove = (obj: any) => {
    onChange?.(collection.filter(r => r !== obj));
  }

  const AddDialog = addDialog;

  return <div style={style}>
    <List bordered dataSource={collection}
          renderItem={i => <List.Item style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>{format(i)}</div>
            <Button type='link' onClick={() => remove(i)}><DeleteOutlined /></Button>
          </List.Item>}
          style={{ marginBottom: 10 }}
          />
    <DialogButton dialog={props => <AddDialog {...props} onOk={add} initial={initial} />}
                  type="primary">
      Add
    </DialogButton>
  </div>
}