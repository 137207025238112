import {DialogProps} from "components/Modal/DialogProps";
import {FindUsersDocument} from "generated/graphql";
import {Picker} from "components/Picker/Picker";
import {DialogButton} from "components/Modal/DialogButton";
import {AddUserDialog} from "routes/Editor/Dialogs/AddUserDialog";
import {VerticalSpace} from "components/Layout/VerticalSpace";

export interface PickedUser {
  id: number;
  displayName: string;
}

interface Props {
  onPick: (pick: PickedUser) => void;
}

export const UserPickerProperties = {
  queryDocument: FindUsersDocument,
  placeholder: "Search by name or email",
  render: (u: any) => `${u.displayName} (${u.mailAddress})`
};

export const UserPicker = (props: DialogProps & Props) => {
  return <Picker {...props} {...UserPickerProperties}>
    <VerticalSpace size="large" />
    <div style={{ textAlign: 'center' }}>
      <DialogButton type='default' dialog={pr => <AddUserDialog {...pr} onCreate={u => props.onPick(u)} />}>New user</DialogButton>
    </div>
  </Picker>
}