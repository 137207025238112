import {Input, Layout} from "antd";
import Sider from "antd/es/layout/Sider";
import {Content} from "antd/es/layout/layout";
import {Link, Outlet, useNavigate} from "react-router-dom";
import styles from "./Editor.module.css";
import {useQuery} from "@apollo/client";
import {ArticleStatus, GetArticleCountsDocument, UserAction} from "generated/graphql";
import {formatStatus} from "utilities/formatters";
import {useAuth} from "hooks/useAuth";
import {useJournal} from "hooks/useJournal";

export const Editor = () => {
  const { data } = useQuery(GetArticleCountsDocument);
  const counts = data?.articleCounts;
  const journal = useJournal();

  const { can } = useAuth();

  const StatusLink = ({ status } : { status: ArticleStatus }) => {
    const count = counts?.filter(c => c.status === status)[0];
    return <div className={styles.link}><Link to={`${status}`}>{formatStatus(status)}</Link><span>{count?.count}</span></div>
  }

  const navigate = useNavigate();

  return <Layout style={{ background: "transparent" }}>
    <Sider theme="light" className={styles.sider} width={160}>
      <h3>Options</h3>
      <Link to="submit">Add new paper</Link>
      { can(UserAction.ManageTemplates) && <Link to="templates">Edit letters</Link> }
      { can(UserAction.ViewUsers) && <Link to="users">Manage users</Link> }
      { can(UserAction.ManageIssues) && <Link to="issues">Manage issues</Link> }
      { can(UserAction.ManagePages) && <Link to="pages">Manage pages</Link> }
      { can(UserAction.ManageForms) && <Link to="forms">Manage forms</Link> }
      <Link to="statistics">Statistics</Link>

      <h3>Custom lists</h3>
      <Link to="ACTIVE">All active</Link>
      <Link to="OVERDUE_REFS">Overdue refs</Link>
      <Link to="OPEN_REQUEST">Open requests</Link>
      <Link to="search">Advanced search</Link>

      <h3>Editor action</h3>
      <StatusLink status={ArticleStatus.Submitted} />
      <StatusLink status={ArticleStatus.Revised} />
      <StatusLink status={ArticleStatus.Reviewed} />
      <StatusLink status={ArticleStatus.Refereed} />

      <h3>Pipeline</h3>
      <StatusLink status={ArticleStatus.RequestSent} />
      <StatusLink status={ArticleStatus.AtQuickOpinion} />
      <StatusLink status={ArticleStatus.AtReferee} />
      <StatusLink status={ArticleStatus.MajorRevision} />
      <StatusLink status={ArticleStatus.MinorRevision} />

      <h3>Done</h3>
      <StatusLink status={ArticleStatus.Accepted} />
      <StatusLink status={ArticleStatus.Rejected} />
      <StatusLink status={ArticleStatus.FinalReceived} />
      <StatusLink status={ArticleStatus.Uploaded} />
      { journal.featureSettings.useAppeared && <StatusLink status={ArticleStatus.Appeared} /> }

      <h3>Quick navigation</h3>
      <Input type="number" onKeyPress={ev => ev.key === "Enter" && navigate(`/editor/article/${ev.currentTarget.value}`) } />
    </Sider>
    <Content>
      <Outlet />
    </Content>
  </Layout>
}