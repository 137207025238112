import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { GraphqlProvider } from 'providers/GraphqlProvider';
import { ConfigProvider } from 'antd';
import {PageRouter} from "providers/PageRouter";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider theme={{
      token: {
        colorPrimary: '#2a55ab',
        borderRadius: 0,
        colorLink: '#2a55ab',
        padding: 8,
        paddingContentVerticalLG: 4,
        paddingContentVertical: 4
      }
    }}>
      <GraphqlProvider>
        <PageRouter />
      </GraphqlProvider>
    </ConfigProvider>
  </React.StrictMode>
);