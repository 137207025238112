import {Button, DatePicker, Form, Input, InputNumber, Select} from "antd";
import {AccessLevel, ArticleStatus, GetArticlesDocument, GetUsersDocument} from "generated/graphql";
import {getEntries} from "utilities/enumHelpers";
import {useQuery} from "@apollo/client";
import {useState} from "react";
import {DataTable} from "components/Table/DataTable";
import {VerticalSpace} from "components/Layout/VerticalSpace";
import {useColumns} from "hooks/useColumns";

export const ArticleSearch = () => {
  const [values, setValues] = useState<any>({});

  const { data, loading } = useQuery(GetArticlesDocument, {
    variables: { input: values },
    skip: Object.values(values).filter(f => f).length === 0
  });

  const { data: editors } = useQuery(GetUsersDocument, { variables: { level: AccessLevel.Editor } });

  const [form] = Form.useForm();

  const search = async () => {
    setValues(await form.validateFields());
  }

  const columns = useColumns(false);

  return <>
    <Form form={form}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 15 }}
          style={{ maxWidth: 500 }}
    >
      <Form.Item label="First number" name="numberMin">
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item label="Last number" name="numberMax">
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item label="Submitted after" name="submitStart">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Submitted before" name="submitEnd">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Author" name="authorName">
        <Input />
      </Form.Item>
      <Form.Item label="Corresponding author" name="correspondingAuthorName">
        <Input />
      </Form.Item>
      <Form.Item label="Title" name="title">
        <Input />
      </Form.Item>
      <Form.Item label="Referee" name="refereeName">
        <Input />
      </Form.Item>
      <Form.Item label="Managing editor" name="managingEditorId">
        <Select options={editors?.users.map(s => ({ label: s.displayName, value: s.id }))} allowClear={true} />
      </Form.Item>
      <Form.Item label="Status" name="statuses">
        <Select options={getEntries(ArticleStatus).map(s => ({ label: s.label, value: s.entry }))}
                allowClear={true} mode="multiple" />
      </Form.Item>
    </Form>
    <Button loading={loading} type="primary" onClick={search}>Search</Button>
    <VerticalSpace size="large" />
    { data && <DataTable source={data?.articles} columns={columns} /> }
  </>
}