import {Button, Form, Modal, Upload} from "antd";
import {useState} from "react";
import {DialogProps} from "components/Modal/DialogProps";
import TextArea from "antd/es/input/TextArea";
import {useReplaceFile} from "hooks/useReplaceFile";

interface Props {
  refereeRequestId?: number;
  referee?: string;
}

export const ReplaceFileDialog = ({ open, onClose, refereeRequestId, referee }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const replaceFile = useReplaceFile();

  const handleOk = async () => {
    const { file, comment } = await form.validateFields();
    if (!file?.file) {
      alert("Upload a file");
      return;
    }
    setLoading(true);
    await replaceFile({
      comment: comment,
      file: file?.file,
      refereeRequestId: refereeRequestId ?? 0 // TODO: correct typing
    });
    setLoading(false);
    form.resetFields();
    onClose();
  };

  return <Modal title={`Replace file for ${referee}`}
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <div style={{ fontStyle: "italic", marginBottom: "10px" }}>Warning: the original file will be permanently deleted.</div>
    <Form form={form} layout="vertical">
      <Form.Item name="file" label="File" valuePropName="file">
        <Upload accept=".pdf,.txt" maxCount={1} beforeUpload={() => false}>
          <Button type="primary">Choose file</Button>
        </Upload>
      </Form.Item>
      <Form.Item name="comment" label="Comment">
        <TextArea rows={5} />
      </Form.Item>
    </Form>
  </Modal>
}