import {useQuery} from "@apollo/client";
import {GetFormDocument} from "generated/graphql";
import {DialogButton} from "components/Modal/DialogButton";
import {VerticalSpace} from "components/Layout/VerticalSpace";
import {formatValue} from "utilities/enumHelpers";
import {EditFormQuestionDialog} from "routes/Editor/Dialogs/EditFormQuestionDialog";
import {useParams} from "react-router-dom";
import {DragDataTable} from "components/Table/DragDataTable";
import {useEditFormQuestion} from "hooks/useEditFormQuestion";

export const FormDetail = () => {
  const { id } = useParams();
  const { data, loading, refetch } = useQuery(GetFormDocument, { variables: { id: +(id ?? '0') } });

  const editQuestion = useEditFormQuestion();

  return <>
    { data && <>
        <DialogButton type='primary'
                      dialog={props => <EditFormQuestionDialog {...props} onComplete={refetch} formId={data?.form.id} />}>
            Add question
        </DialogButton>
        <VerticalSpace />
        <DragDataTable source={data.form.questions.filter(q => !q.isDeleted).sort((a,b) => a.index - b.index)}
                       key={data.form.questions.filter(q => !q.isDeleted).length}
                       onMove={(from, to) => editQuestion({ id: from.id, index: to.index })}
                       columns={[
        {
          key: 'Type',
          value: t => formatValue(t.type),
          filter: true
        },
        {
          key: 'Identifier',
          value: t => t.identifier
        },
        {
          key: 'Text',
          value: t => t.text
        },
        {
          key: 'Required',
          value: t => t.isRequired ? '✓' : ''
        },
                         {
                           key: 'Index',
                           value: t => t.index
                         },
        {
          key: 'Action',
          header: <></>,
          render: t => <>
            <DialogButton dialog={props => <EditFormQuestionDialog {...props} question={t} />}>Edit</DialogButton>
          </>
        }
      ]} />
    </>  }
    { loading && <i>Loading...</i> }
  </>
}