import {SendMailDocument, SendMailInput} from "generated/graphql";
import {useCheckedMutation} from "hooks/useCheckedMutation";

export const useSendMail = () => {
  const [mutate, { loading }] = useCheckedMutation(SendMailDocument, {
    update(cache, { data }) {
      const entry = data?.sendMail.mailEntry;
      if (!entry) return;
      cache.modify({
        id: `Article:${entry.articleId}`,
        fields: {
          refereeRequests: (existing: readonly any[]) => [entry, ...existing]
        }
      })
    }
  });

  return {
    sendMail: (input: SendMailInput) => mutate( { input }),
    loading
  };
}