import {ArticleStatus, EventInfoFragment, EventType, TemplateType} from "generated/graphql";
import {getEntries} from "utilities/enumHelpers";
import dayjs from "dayjs";

const statusMap = new Map(getEntries(ArticleStatus).map(m => [m.entry, m.label]));

export const formatStatus = (status: ArticleStatus) => statusMap.get(status);

export const formatEvent = (event: EventInfoFragment) => {
  if (!event) return '';
  const referee = event.refereeRequest?.referee.displayName;
  switch (event.type) {
    case EventType.ChangeStatus: return `Status changed to ${formatStatus(event.status!)}`
    case EventType.InsertPaper: return "Insert manuscript";
    case EventType.UploadPaper: return "Upload manuscript";
    case EventType.RefereeAccepted: return `${referee} has agreed to referee`;
    case EventType.RefereeReinstated: return `Referee request for ${referee} reinstated`;
    case EventType.RefereeRequest: return `Referee request sent to ${referee} (${event.length} days)`;
    case EventType.UploadRefereeReport: return "Submit referee report for " + referee;
    case EventType.ReplaceFile: return "Replace file for " + referee;
    case EventType.UpdateAllottedDays: return `Extend referee request ${referee ? `for ${referee} ` : ''}to ${event.length} days`;
    case EventType.UploadRevision: return "Upload revision";
    case EventType.RefereeClosed: return `Referee request for ${referee} closed`;
    case EventType.RefereeReminder: return `Reminder sent to ${referee}` + (event.template ? ` (${event.template?.name})` : "");
    case EventType.AuthorMail: return `Mail sent to author`;
    case EventType.AddComment: return "Comment: " + event.comment;
    case EventType.AssignHandlingEditor: return event.targetUser
      ? `Assign ${event.targetUser?.displayName} as handling editor`
      : "Decline to handle submission";
    case EventType.AcceptHandlingEditor: return "Agree to handle submission";
    case EventType.NoReplyReminder: return `Reminder (no reply) sent to ${referee}`;
    case EventType.EditorNotification: return `Overdue notification sent to handling editor for ${referee}`;
  }
  return `${event.type}: ${event.comment}`;
}

export const formatDate = (date: any) => date ? dayjs(date).format('DD MMM YYYY') : '';
export const formatDateTime = (date: any) => date ? dayjs(date).format('DD MMM YYYY HH:mm') : '';

export const formatMonth = (date: any) => date ? dayjs(date).format('MMMM YYYY') : '';

const templateTypeMap = new Map([
  [TemplateType.Author, "Author"],
  [TemplateType.RefereeReminder, "Referee (reminder)"],
  [TemplateType.RefereeRequest, "Referee (request)"],
  [TemplateType.RefereeMail, "Referee (other)"],
  [TemplateType.HandlingEditor, "Handling editor"],
  [TemplateType.AutomatedReminderNoReply, "Automated reminder (no reply)"],
  [TemplateType.AutomatedReminderOverdue, "Automated reminder (overdue)"],
  [TemplateType.EditorNotificationNoReply, "Editor notification (no reply)"],
  [TemplateType.EditorNotificationOverdue, "Editor notification (overdue)"],
  [TemplateType.EditorMail, "Mail to editors"]
]);
export const formatTemplateType = (type: TemplateType) => templateTypeMap.get(type);

export const toSeparatedString = (vals: string[], separator: string = ", ", word?: string) => {
  if (!word) return vals.join(separator);
  if (vals.length === 0) return "";
  if (vals.length === 1) return vals[0];
  return `${vals.slice(0, vals.length - 1).join(separator)} ${word} ${vals[vals.length - 1]}`;
}