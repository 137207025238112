import {useQuery} from "@apollo/client";
import {GetJournalDocument} from "generated/graphql";

export const useJournal = () => {
  const { data } = useQuery(GetJournalDocument);

  return data?.journal ?? {
    code: '',
    name: '',
    url: '',
    editorAddress: '',
    featureSettings: {
      disableHandlingEditor: false,
      hideFlagForEditors: false,
      replyByDaysQuickOpinion: 14,
      replyByDaysFullReport: 14,
      useAppeared: false,
      useCopyrightAgreement: false
    }
  }
}