import {Form, Input, Modal} from "antd";
import {useState} from "react";
import {DialogProps} from "components/Modal/DialogProps";
import {required} from "utilities/formRules";
import {useEditUser} from "hooks/useEditUser";

interface Props {
  user: { id: number, lastName: string, firstName: string }
}

export const EditUserNameDialog = ({ open, onClose, user }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const editUser = useEditUser();

  const handleOk = async () => {
    const values = await form.validateFields();
    setLoading(true);
    await editUser({
      userId: user.id,
      ...values
    });
    setLoading(false);
    onClose();
  };

  return <Modal title="Edit user name"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical" requiredMark={false}
          initialValues={{ lastName: user.lastName, firstName: user.firstName }}>
      <Form.Item name="firstName" label="First name" rules={required}>
        <Input />
      </Form.Item>
      <Form.Item name="lastName" label="Last name" rules={required}>
        <Input />
      </Form.Item>
    </Form>
  </Modal>
}