import {MailInfoFragment} from "generated/graphql";
import {Button, Descriptions, Modal} from "antd";
import {DialogProps} from "components/Modal/DialogProps";
import {FileLink} from "components/Navigation/FileLink";

interface Props {
  mail: MailInfoFragment
}

const { Item } = Descriptions;

export const ViewMailDialog = ({ open, onClose, mail }: DialogProps & Props) => {
  return <Modal title="Mail message"
                open={open}
                footer={<Button onClick={onClose}>Close</Button>}
                onCancel={onClose}
                width="800px"
  >
    <Descriptions column={1} bordered>
      <Item label="From">
        { mail.from && <>{mail.from.displayName}</> }
        { !mail.from && <i>System</i> }
      </Item>
      <Item label="To">
        { mail.to.map(t => t.displayName ?? t.mailAddress).join(', ') }
      </Item>
      { mail.cc.length > 0 && <Item label="Cc">
        { mail.cc.map(t => t.displayName ?? t.mailAddress).join(', ') }
      </Item>}
      <Item label="Subject">
        { mail.subject }
      </Item>
      { mail.attachments.length > 0 && <Item label="Attachments">
        { mail.attachments.map((a,i) => <><FileLink file={a} key={a.id} />{ i !== mail.attachments.length - 1 ? ", " : "" }</>) }
      </Item> }
    </Descriptions>
    <iframe title="body" srcDoc={mail.isHtml ? mail.body : mail.body.replaceAll("\n", "\n<br/>")} />
  </Modal>
}