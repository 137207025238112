import {Button, Form, Input, InputNumber, Upload} from "antd";
import TextArea from "antd/es/input/TextArea";
import {CollectionEditor} from "components/Input/CollectionEditor";
import {EditAuthorDialog} from "routes/Dialogs/EditAuthorDialog";
import {required} from "utilities/formRules";
import {useState} from "react";
import {useCreateArticleVersion} from "hooks/useCreateArticleVersion";
import {useAuth} from "hooks/useAuth";
import {useJournal} from "hooks/useJournal";
import {GetArticleForRevisionDocument, UserAction} from "generated/graphql";
import {Navigate, useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {UserPickElement} from "components/Picker/UserPickElement";
import {useWatch} from "antd/es/form/Form";

export const Submit = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const createArticleVersion = useCreateArticleVersion();
  const [number, setNumber] = useState<number | null>(null);

  const { number: targetNumber } = useParams();
  const { data, loading: articleLoading } = useQuery(GetArticleForRevisionDocument, {
    variables: { number: +(targetNumber ?? "") },
    skip: !targetNumber
  });
  const article = data?.article;

  const authors = useWatch("authors", form);

  const submit = async () => {
    const { author, file, commentFile, ...values } = await form.validateFields();
    setLoading(true);
    const res = await createArticleVersion({
      articleId: article?.id,
      authorId: author.id,
      file: file.file,
      commentFile: commentFile?.file,
      ...values
    });
    setNumber(res.data?.createArticleVersion.number ?? null);
    setLoading(false);
  }

  const { user, can } = useAuth();
  const journal = useJournal();

  return <>
    { !number && !articleLoading &&
    <Form form={form} requiredMark={false} layout="vertical" style={{ maxWidth: 800 }}
          initialValues={{
            author: article?.author ?? { id: user?.id, displayName: user?.displayName },
            title: article?.title,
            authors: article?.authors.map(a => ({
              firstName: a.firstName,
              lastName: a.lastName,
              email: a.email,
              affiliation: a.affiliation,
              countryCode: a.countryCode
            }))
    }}>
      <Form.Item name="author" label="Corresponding author" rules={required}>
        <UserPickElement disabled={!can(UserAction.ManageArticles)} />
      </Form.Item>
      <Form.Item name="title" label="Title" rules={required}>
        <Input />
      </Form.Item>
      <Form.Item label="Authors" rules={required}>
        <div style={{ fontSize: "small", marginBottom: "5px" }}>Enter all authors (<b>INCLUDING</b> yourself) in the order they appear in the paper.</div>
        <Form.Item name="authors" rules={required}>
          <CollectionEditor format={a => `${a.firstName} ${a.lastName}`}
                            addDialog={EditAuthorDialog}
                            style={{ width: "500px" }}
                            initial={authors?.length ? {} : {
                              firstName: user?.firstName,
                              lastName: user?.lastName,
                              email: user?.mailAddress,
                              affiliation: user?.affiliation
                            }} />
        </Form.Item>
      </Form.Item>
      <Form.Item name="file" label="File" rules={required}>
        <Upload accept=".pdf" maxCount={1} beforeUpload={_ => false}>
          <Button type="primary">Choose file</Button>
        </Upload>
      </Form.Item>
      <Form.Item name="pageCount" label="Number of pages" rules={required}>
        <InputNumber />
      </Form.Item>
      <Form.Item name="comment" label="Comment">
        <TextArea rows={10} />
      </Form.Item>
      { article && <Form.Item name="commentFile" label="Additional file">
          <Upload accept=".pdf" maxCount={1} beforeUpload={_ => false}>
              <Button type="primary">Choose file</Button>
          </Upload>
      </Form.Item> }
      <Button size="large" loading={loading} type="primary" onClick={submit}>Submit</Button>
    </Form> }
    { number && !article && <p>Thank you for submitting. Your manuscript has number {journal.code} {number}.</p> }
    { number && article && <Navigate to=".." /> }
  </>
};