import {useMutation} from "@apollo/client";
import {AssignEditorDocument, AssignEditorInput} from "generated/graphql";

export const useAssignEditor = () => {
  const [mutate] = useMutation(AssignEditorDocument, {
    update(cache, { data }) {
      const ev = data?.assignEditor.event;
      if (!ev) return;
      cache.modify({
        id: `Article:${ev.articleId}`,
        fields: {
          events: (existing: readonly any[]) => [ev, ...existing]
        }
      })
    }
  });

  return (input: AssignEditorInput) => mutate({ variables: { input } });
}