import {useQuery} from "@apollo/client";
import {GetIssuesDocument} from "generated/graphql";
import {DataTable} from "components/Table/DataTable";
import {formatMonth} from "utilities/formatters";
import {DialogButton} from "components/Modal/DialogButton";
import {VerticalSpace} from "components/Layout/VerticalSpace";
import {AddIssueDialog} from "routes/Editor/Dialogs/AddIssueDialog";
import {Link} from "react-router-dom";

export const IssueList = () => {
  const { data, loading, refetch } = useQuery(GetIssuesDocument);

  return <>
    { loading && <i>Loading...</i> }
    { data && <>
        <DialogButton type='primary' dialog={props => <AddIssueDialog {...props} onComplete={refetch} />}>Add new issue</DialogButton>
        <VerticalSpace />
        <DataTable source={data.issues} columns={[
          {
            key: 'Volume',
            value: t => t.volume
          },
          {
            key: 'Issue',
            value: t => t.issueNumber
          },
          {
            key: 'Date',
            render: t => <Link to={`/editor/issues/${t.id}`}>{ formatMonth(t.date)} </Link>
          }
        ]}
        />
    </> }
  </>
}