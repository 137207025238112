import {Checkbox, Form, Modal, Select} from "antd";
import {useState} from "react";
import {DialogProps} from "components/Modal/DialogProps";
import {required} from "utilities/formRules";
import {useAddArticleEvent} from "hooks/useAddArticleEvent";
import {ArticleStatus} from "generated/graphql";
import {formatStatus} from "utilities/formatters";
import {useJournal} from "hooks/useJournal";

interface Props {
  articleId: number
  onNotify?: () => void
  allowEditorNotification: boolean
}

const statuses = [
  ArticleStatus.Submitted, ArticleStatus.RequestSent, ArticleStatus.AtQuickOpinion,
  ArticleStatus.AtReferee, ArticleStatus.Reviewed,
  ArticleStatus.Refereed, ArticleStatus.MinorRevision, ArticleStatus.MajorRevision,
  ArticleStatus.Revised, ArticleStatus.Rejected, ArticleStatus.Accepted,
  ArticleStatus.Uploaded, ArticleStatus.FinalReceived, ArticleStatus.Appeared
];

export const EditStatusDialog = ({ open, onClose, articleId, onNotify, allowEditorNotification }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [notifyAuthor, setNotifyAuthor] = useState(false);
  const [notifyEditor, setNotifyEditor] = useState(false);
  const addEvent = useAddArticleEvent();
  const journal = useJournal();

  const handleOk = async () => {
    const values = await form.validateFields();
    setLoading(true);
    await addEvent({
      ...values,
      articleId,
      notifyEditor
    });
    setLoading(false);
    onClose();
    if (notifyAuthor) {
      onNotify?.();
    }
  };

  return <Modal title="Change status"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical">
      <Form.Item name="status" label="Status" rules={required}>
        <Select options={statuses
          .filter(s => s !== ArticleStatus.Appeared || journal.featureSettings.useAppeared)
          .map(s => ({ label: formatStatus(s), value: s }))} />
      </Form.Item>
      <Checkbox onChange={c => setNotifyAuthor(c.target.checked)} checked={notifyAuthor}>Notify author</Checkbox>
      { allowEditorNotification && <Checkbox onChange={c => setNotifyEditor(c.target.checked)} checked={notifyEditor}>Notify handling editor</Checkbox> }
    </Form>
  </Modal>
}