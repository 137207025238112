import {DialogProps} from "components/Modal/DialogProps";
import {useState} from "react";
import {DatePicker, Form, InputNumber, Modal} from "antd";
import {required} from "utilities/formRules";
import {useEditIssue} from "hooks/useEditIssue";
import dayjs from "dayjs";

interface Props {
  onComplete?: () => void;
}

export const AddIssueDialog = ({ open, onClose, onComplete }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const editIssue = useEditIssue();

  const handleOk = async () => {
    const { date, ...values } = await form.validateFields();
    setLoading(true);
    await editIssue({ id: 0, ...values, date: dayjs(date).format("YYYY-MM-01") });
    setLoading(false);
    onClose();
    onComplete?.();
  };

  return <Modal title="Add issue"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical" initialValues={{ date: dayjs() }}>
      <Form.Item name="volume" label="Volume" rules={required}>
        <InputNumber />
      </Form.Item>
      <Form.Item name="issueNumber" label="Issue" rules={required}>
        <InputNumber />
      </Form.Item>
      <Form.Item name="date" label="Date" rules={required}>
        <DatePicker picker="month" />
      </Form.Item>
    </Form>
  </Modal>
}