import {Alert, Form, InputNumber, Modal} from "antd";
import {useState} from "react";
import {GetUserInfoDocument, RefereeInfoFragment, RefereeRequestType} from "generated/graphql";
import {useAssignReferee} from "hooks/useAssignReferee";
import {DialogProps} from "components/Modal/DialogProps";
import {required} from "utilities/formRules";
import {EnumSelect} from "components/Input/EnumSelect";
import {UserPickElement} from "components/Picker/UserPickElement";
import {useQuery} from "@apollo/client";
import {formatDate, formatStatus} from "utilities/formatters";
import {useJournal} from "hooks/useJournal";

interface Props {
  articleId: number
  articleNumber: number
  onCreate: (req: RefereeInfoFragment) => void
}

export const AddRefereeDialog = ({ open, onClose, articleId, articleNumber, onCreate }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [refereeId, setRefereeId] = useState<number | null>(null);
  const assignReferee = useAssignReferee();
  const journal = useJournal();

  const userInfoQuery = useQuery(GetUserInfoDocument, { variables: { id: refereeId ?? 0 }, skip: !refereeId })
  const refereeInfo = userInfoQuery.data?.userInfo.refereeInfo;

  const handleOk = async () => {
    const { user, ...values } = await form.validateFields();
    setLoading(true);
    const req = await assignReferee({ ...values,
      articleId,
      userId: user.id
    });
    setLoading(false);
    onClose();
    if (req.data?.assignReferee.refereeRequest) onCreate(req.data?.assignReferee.refereeRequest);
    form.resetFields();
    setRefereeId(null);
  };

  return <Modal title="Add referee"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical">
      <Form.Item name="user" label="Referee" rules={required}>
        <UserPickElement onChange={v => setRefereeId(v.id)} />
      </Form.Item>
      { refereeInfo && <>
        <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <div> {refereeInfo.comment}</div>
          { refereeInfo.lastReportDate && <div>Last referee report {formatDate(refereeInfo.lastReportDate)}</div> }
          { refereeInfo.lastClosedDate && <div>Last request closed {formatDate(refereeInfo.lastClosedDate)}</div> }
          { refereeInfo.currentRequests > 0 && <div>Currently refereeing {refereeInfo.currentRequests} article{(refereeInfo.currentRequests > 1 ? "s" :"")}</div> }
          { refereeInfo.activeArticle && <div>Submitted manuscript {formatDate(refereeInfo.activeArticle.submitDate)} ({journal.code} {refereeInfo.activeArticle.number}, {formatStatus(refereeInfo.activeArticle.status)})</div> }
          { refereeInfo.possibleArticles.length > 0 && <div>Possible submitted manuscript {refereeInfo.possibleArticles.map(a => <span>{formatDate(a.submitDate)} ({journal.code} {a.number}, {formatStatus(a.status)})</span>)}</div> }
          { [refereeInfo.activeArticle, ...refereeInfo.possibleArticles].filter(u => u?.number === articleNumber).length > 0 &&
              <Alert style={{ marginTop: "10px" }} type="warning" message="The selected user appears to be an author of this article" showIcon /> }
        </div>
      </>}
      <Form.Item name="type" label="Type" rules={required}>
        <EnumSelect type={RefereeRequestType} />
      </Form.Item>
      <Form.Item name="duration" label="Number of days" rules={required}>
        <InputNumber min={0} />
      </Form.Item>
    </Form>
  </Modal>
}