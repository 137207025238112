import {DialogProps} from "components/Modal/DialogProps";
import {useState} from "react";
import {Form, Input, InputNumber, Modal} from "antd";
import {required} from "utilities/formRules";
import {Page} from "generated/graphql";
import TextArea from "antd/es/input/TextArea";
import {useEditPage} from "hooks/useEditPage";

interface Props {
  page?: Page;
  onComplete?: () => void;
}

const restrictedPages = ["editor", "submit", "portal"];

export const EditPageDialog = ({ open, onClose, page, onComplete }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const editPage = useEditPage();

  const handleOk = async () => {
    const values = await form.validateFields();
    setLoading(true);
    await editPage({ id: page?.id ?? 0, ...values });
    setLoading(false);
    onClose();
    onComplete?.();
  };

  return <Modal title="Edit page"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
                width="800px"
  >
    <Form form={form} layout="vertical" initialValues={page}>
      <Form.Item name="path" label="Path" rules={page ? [] : required}>
        <Input />
      </Form.Item>
      <Form.Item name="name" label="Name" rules={required}>
        <Input />
      </Form.Item>
      <Form.Item name="index" label="Index" rules={required}>
        <InputNumber />
      </Form.Item>
      <Form.Item name="description" label="Description" rules={required}>
        <TextArea rows={5} />
      </Form.Item>
      { !restrictedPages.includes(page?.path ?? "") && <Form.Item name="content" label="Content">
        <TextArea rows={15} />
      </Form.Item> }
    </Form>
  </Modal>
}