import {Form, Modal} from "antd";
import {useState} from "react";
import {DialogProps} from "components/Modal/DialogProps";
import {useEditUser} from "hooks/useEditUser";
import TextArea from "antd/es/input/TextArea";

interface Props {
  userId: number
  refereeInfo?: string | null
}
export const EditRefereeInfoDialog = ({ open, onClose, userId, refereeInfo }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const editUser = useEditUser();

  const handleOk = async () => {
    const values = await form.validateFields();
    setLoading(true);
    await editUser({
      ...values,
      userId
    });
    setLoading(false);
    onClose();
  };

  return <Modal title="Edit referee info"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical" initialValues={{ refereeInfo }}>
      <Form.Item name="refereeInfo" label="Referee info">
        <TextArea rows={5} />
      </Form.Item>
    </Form>
  </Modal>
}