import {DialogProps} from "components/Modal/DialogProps";
import {useState} from "react";
import {Button, Checkbox, Form, Input, Modal} from "antd";
import {required} from "utilities/formRules";
import {EnumSelect} from "components/Input/EnumSelect";
import {FormQuestionInput, QuestionType} from "generated/graphql";
import {useEditFormQuestion} from "hooks/useEditFormQuestion";

interface Props {
  question?: FormQuestionInput
  onComplete?: () => void
  formId?: number
}

const { confirm } = Modal;

export const EditFormQuestionDialog = ({ open, onClose, question, onComplete, formId }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const editFormQuestion = useEditFormQuestion();

  const handleOk = async () => {
    const values = await form.validateFields();
    setLoading(true);
    await editFormQuestion({ id: question?.id ?? 0, ...values, formId: formId ?? question?.formId });
    setLoading(false);
    onClose();
    onComplete?.();
  };

  return <Modal title="Edit question"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
                width="600px"
  >
    <Form form={form} layout="vertical" initialValues={question} requiredMark={false}>
      <Form.Item name="type" label="Type" rules={required}>
        <EnumSelect type={QuestionType} />
      </Form.Item>
      <Form.Item name="identifier" label="Identifier" style={{ width: 300 }} rules={required}>
        <Input />
      </Form.Item>
      <Form.Item name="text" label="Text" rules={required}>
        <Input />
      </Form.Item>
      <Form.Item name="isRequired" label="Required" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      { question && <Button style={{ float: 'left' }} type='link' onClick={() => confirm({
        title: 'Delete question',
        content: `Are you sure you want to delete ${question.identifier}?`,
        onOk: async () => {
          const { __typename: _, ...values } = question as any;
          await editFormQuestion({ ...values, isDeleted: true } );
          onClose();
          onComplete?.();
        }
      })}>Delete</Button> }
    </Form>
  </Modal>
}