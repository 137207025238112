import {Form, Input, Modal} from "antd";
import {useState} from "react";
import {DialogProps} from "components/Modal/DialogProps";
import {email, required} from "utilities/formRules";
import {useCreateAccount} from "hooks/useCreateAccount";
import {PickedUser} from "components/Picker/UserPicker";

interface Props {
  onCreate: (user: PickedUser) => void
}

export const AddUserDialog = ({ open, onClose, onCreate }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const createAccount = useCreateAccount();

  const handleOk = async () => {
    const { user, ...values } = await form.validateFields();
    setLoading(true);
    const res = await createAccount(values);
    setLoading(false);
    onClose();
    if (res.data?.createAccount.user) onCreate?.(res.data.createAccount.user);
  };

  return <Modal title="Create new user"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical">
      <Form.Item name="firstName" label="First name" rules={required}>
        <Input />
      </Form.Item>
      <Form.Item name="lastName" label="Last name" rules={required}>
        <Input />
      </Form.Item>
      <Form.Item name="affiliation" label="Affiliation">
        <Input />
      </Form.Item>
      <Form.Item name="mailAddress" label="E-mail address" rules={email}>
        <Input type="email" />
      </Form.Item>
    </Form>
  </Modal>
}