import {useRoutes} from "hooks/useRoutes";
import {RouterProvider} from "react-router-dom";

export const PageRouter = () => {
  const routes = useRoutes();

  return <>
    { routes && <RouterProvider router={routes} /> }
    { !routes && <i>Loading...</i> }
  </>
}