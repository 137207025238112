import {DataTable} from "components/Table/DataTable";
import {useQuery} from "@apollo/client";
import {GetPagesDocument} from "generated/graphql";
import {DialogButton} from "components/Modal/DialogButton";
import {VerticalSpace} from "components/Layout/VerticalSpace";
import {EditPageDialog} from "routes/Editor/Dialogs/EditPageDialog";

export const PageList = () => {
  const { data, loading, refetch } = useQuery(GetPagesDocument);

  return <>
    { data && <>
        <DialogButton type='primary' dialog={props => <EditPageDialog {...props} onComplete={refetch} />}>Add new page</DialogButton>
        <VerticalSpace />
        <DataTable source={data.pages} columns={[
        {
          key: 'Index',
          value: t => t.index
        },
        {
          key: 'Name',
          value: t => t.name
        },
        {
          key: 'Path',
          value: t => t.path
        },
        {
          key: 'Action',
          header: <></>,
          render: t => <> <DialogButton dialog={props => <EditPageDialog {...props} page={t} />}>Edit</DialogButton> </>
        }
      ]} />
    </>  }
    { loading && <i>Loading...</i> }
  </>
}