import {useQuery} from "@apollo/client";
import {ArticleStatus, CustomList, GetPagesDocument, UserAction} from "generated/graphql";
import {createBrowserRouter, RouteObject} from "react-router-dom";
import {ContentPage} from "routes/ContentPage";
import App from "App";
import {Editor} from "routes/Editor/Editor";
import {getEntries} from "utilities/enumHelpers";
import {AllArticleList, ArticleList} from "routes/Editor/ArticleList";
import {ArticleDetail} from "routes/Editor/ArticleDetail";
import {TemplateList} from "routes/Editor/TemplateList";
import {Submit} from "routes/Submit";
import {UserList} from "routes/Editor/UserList";
import {UserDetail} from "routes/UserDetail";
import {ArticleListWithReferees} from "routes/Editor/ArticleListWithReferees";
import {IssueDetail} from "routes/Editor/IssueDetail";
import {IssueList} from "routes/Editor/IssueList";
import {ArticleSearch} from "routes/Editor/ArticleSearch";
import {PageList} from "routes/Editor/PageList";
import {Callback} from "routes/Callback";
import {AuthGuard} from "components/Navigation/AuthGuard";
import {useJournal} from "hooks/useJournal";
import {FormList} from "routes/Editor/FormList";
import {FormDetail} from "routes/Editor/FormDetail";
import {Referee} from "routes/Referee";
import {Assigned} from "../routes/Assigned";
import {Issue} from "routes/Issue";
import {Login} from "routes/Login";
import {Statistics} from "routes/Editor/Statistics";

export const useRoutes = () => {
  const { data } = useQuery(GetPagesDocument);
  const pages = data?.pages;
  const journal = useJournal();
  if (!pages) return null;

  const pageRoutes = pages.filter(m => m.content).map<RouteObject>(m => ({
    index: !m.parentId,
    path: !m.parentId ? undefined : m.path,
    element: <ContentPage page={m} />,
    handle: m.parentId &&{ title: m.name }
  }));

  const routes = [...pageRoutes,
    {
      path: 'callback',
      element: <Callback />
    },
    {
      path: 'submit',
      element: <AuthGuard action={UserAction.Registered}><Submit /></AuthGuard>,
      handle: {  title: 'Submitting a paper' }
    },
    {
      path: 'portal',
      handle: {  title: 'Personal page' },
      children: [
        {
          index: true,
          element: <AuthGuard action={UserAction.Registered}><UserDetail /></AuthGuard>,
        },
        {
          path: 'revision/:number',
          element: <Submit />,
          handle: { title: 'Upload revision' }
        }
      ]
    },
    {
      path: 'login',
      handle: {title: 'Login'},
      element: <AuthGuard action={UserAction.Registered}><Login/></AuthGuard>
    },
    {
      path: 'referee/:token',
      element: <Referee />,
      handle: { title: 'Referee request' }
    },
    {
      path: 'issues/:issueId',
      element: <Issue />,
      handle: { title: 'Issue' }
    },
    {
      path: 'assigned',
      handle: { title: 'Assigned articles'},
      children: [
        {
          index: true,
          element: <AuthGuard action={UserAction.ViewAssignedArticles}><Assigned /></AuthGuard>,
        },
        {
          path: 'article/:number',
          element: <AuthGuard action={UserAction.ViewAssignedArticles}><ArticleDetail /></AuthGuard>,
          handle: { title: `${journal.code} :number` }
        },
      ]
    },
    {
      path: 'editor',
      element: <AuthGuard action={UserAction.ViewArticles}><Editor /></AuthGuard>,
      handle: { title: 'Editor page' },
      children: [
        ...getEntries(ArticleStatus).filter(s => s.entry !== ArticleStatus.RequestSent).map(s => ({
          path: s.entry,
          element: <ArticleList status={s.entry} />,
          handle: { title: s.label }
        })),
        {
          path: 'REQUEST_SENT',
          element: <ArticleListWithReferees />,
          handle: { title: 'Request sent' }
        },
        {
          path: 'ACTIVE',
          element: <AllArticleList />,
          handle: { title: 'All active' }
        },
        {
          path: 'OVERDUE_REFS',
          element: <ArticleListWithReferees custom={CustomList.OverdueRefs} />,
          handle: { title: 'Overdue refs' }
        },
        {
          path: 'OPEN_REQUEST',
          element: <ArticleListWithReferees custom={CustomList.OpenRequest} />,
          handle: { title: 'Open requests' }
        },
        {
          path: 'article/:number',
          handle: { title: `${journal.code} :number` },
          children: [
            {
              element: <ArticleDetail />,
              index: true
            },
            {
              path: 'revision',
              element: <Submit />,
              handle: { title: 'New version' }
            }
          ]
        },
        {
          path: 'templates',
          element: <TemplateList />,
          handle: { title: 'Edit templates' }
        },
        {
          path: 'submit',
          element: <Submit />,
          handle: { title: 'Add new paper'}
        },
        {
          path: 'users',
          element: <UserList />,
          handle: { title: 'Manage users' }
        },
        {
          path: 'users/:id',
          element: <UserDetail />,
          handle: { title: 'User :id' }
        },
        {
          path: 'statistics',
          element: <Statistics />,
          handle: { title: 'Statistics' }
        },
        {
          path: 'issues',
          handle: { title: 'Manage issues' },
          children: [
            {
              index: true,
              element: <IssueList />,
            },
            {
              path: ':issueId',
              handle: { title: 'Issue :issueId' },
              children: [
                {
                  index: true,
                  element: <IssueDetail />,
                },
                {
                  path: "preview",
                  element: <Issue />,
                  handle: { title: 'Preview' }
                }
              ]
            }
          ]
        },
        {
          path: 'search',
          element: <ArticleSearch />,
          handle: { title: 'Advanced search' }
        },
        {
          path: 'pages',
          element: <PageList />,
          handle: { title: 'Manage pages' }
        },
        {
          path: 'forms',
          element: <FormList />,
          handle: { title: 'Manage forms' }
        },
        {
          path: 'form/:id',
          element: <FormDetail />,
          handle: { title: 'Form :id' }
        }
      ]
    }
  ];

  return createBrowserRouter([{
    element: <App />,
    children: routes
  }]);
};