import {MutationHookOptions, TypedDocumentNode, useMutation} from "@apollo/client";
import {notification} from "antd";

export const useCheckedMutation = <TData,TVariables,>(
  mutation: TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>
) => {
  const [mutate, { loading }] = useMutation(mutation, options);

  const checkedMutate = async (x: TVariables) => {
    try {
      await mutate({ variables: x });
    }
    catch(error) {
      notification.open({
        message: 'Processing error',
        description: 'An error has occurred while saving changes. Diagnostic information has been saved',
        placement: "top",
        type: "error",
        duration: null
      });
      throw(error);
    }
  }

  return [checkedMutate, { loading }] as const;
}