import {Form, InputNumber, Modal} from "antd";
import {useState} from "react";
import {DialogProps} from "components/Modal/DialogProps";
import {required} from "utilities/formRules";
import {useUpdateRefereeRequest} from "hooks/useUpdateRefereeRequest";

interface Props {
  refereeRequestId?: number
}

export const ExtendRequestDialog = ({ open, onClose, refereeRequestId }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { extend } = useUpdateRefereeRequest();

  const handleOk = async () => {
    if (!refereeRequestId) return;
    const { file, ...values } = await form.validateFields();
    setLoading(true);
    await extend(refereeRequestId, values.duration);
    setLoading(false);
    onClose();
  };

  return <Modal title="Extend deadline"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical" requiredMark={false}>
      <Form.Item name="duration" label="Number of extra days" rules={required}>
        <InputNumber />
      </Form.Item>
    </Form>
  </Modal>
}