import {Button, Form, Input, Modal, Space} from "antd";
import {useState} from "react";
import {RegisterControl} from "components/Account/RegisterDialog";
import {VerticalSpace} from "components/Layout/VerticalSpace";
import {getOrcIdUrl, setToken} from "utilities/auth";
import {useMutation} from "@apollo/client";
import { CreateTokenDocument } from "generated/graphql";

interface Props {
  open: boolean
  onClose: (cancel?: boolean) => void
}

export const LoginDialog = ({open, onClose}: Props) => {
  const [form] = Form.useForm();
  const [register, setRegister] = useState<boolean | null>(null);
  const [error, setError] = useState(false);

  const [createToken] = useMutation(CreateTokenDocument);

  return <Modal open={open}
                footer={false}
                onCancel={() => onClose(true)}
                title="Login">
    { register === null && <>
        <a href={getOrcIdUrl()}>Login with ORCID</a>
        <VerticalSpace />
        <Form layout="vertical" form={form}
              requiredMark={false}
              onFinish={async () => {
          setError(false);
          let values;
          try {
            values = await form.validateFields();
          } catch { return }
          const res = await createToken({ variables: { input: values } });
          if (!res.data) return;
          if (!res.data.createToken.token) {
            setError(true);
            return;
          }
          setToken(res.data.createToken.token);
          onClose();
        }}>
          <Form.Item name="username" label="E-mail address"
                     rules={[{ required: true, message: 'Please enter your e-mail address' }]}>
            <Input type="email" />
          </Form.Item>
          <Form.Item name="password" label="Password"
                     rules={[{ required: true, message: 'Please enter your password' }]}>
            <Input type="password" />
          </Form.Item>
          { error && <div style={{ color: 'darkred', marginBottom: 15 }}>Invalid username or password</div> }
          <Form.Item>
              <Space>
                  <Button type="primary" htmlType="submit">
                      Login
                  </Button>
                  <Button onClick={() => onClose(true)}>
                      Cancel
                  </Button>
                  <Button type="link" onClick={() => setRegister(false)}>Create new account</Button>
                  <Button type="link" onClick={() => setRegister(true)}>Reset password</Button>
              </Space>
          </Form.Item>
        </Form>
      </> }
    { register !== null && <RegisterControl onCancel={() => setRegister(null)} isReset={register} /> }
  </Modal>
}