import {GetFilesDocument, JournalFile, SendMailAttachmentInput} from "generated/graphql";
import {useQuery} from "@apollo/client";
import {Button, Dropdown, MenuProps, Upload} from "antd";
import {MenuItemType} from "antd/es/menu/interface";

interface Props {
  articleId: number,
  value?: SendMailAttachmentInput[],
  onChange?: (val: SendMailAttachmentInput[]) => void,
  showHidden?: boolean
}

export const AttachmentControl = ({articleId, value = [], onChange, showHidden}: Props) => {

  const {data} = useQuery(GetFilesDocument, {variables: {articleId, showHidden}});

  const files = data?.files ?? [];

  const fileOptions: MenuProps['items'] = [
    ...files.map((f: JournalFile): MenuItemType => ({
      label: `${f.description}: ${f.name}`,
      key: f.id.toString(),
      onClick: event => {
        event.domEvent.stopPropagation();
        onChange?.([...value, {fileId: f.id}])
      }
    })),
    {
      label: 'Upload file',
      key: 'upload'
    }
  ];

  const getFile = (id: number) => {
    const file = files.filter(f => f.id === id)[0];
    return {uid: file.id, name: file.name};
  }

  return <Upload beforeUpload={_ => false}
                 fileList={value.map(u => u.fileId ? getFile(u.fileId) : u.file)}
                 onChange={p => onChange?.(p.fileList.map(z => z.name ? {file: z} : {fileId: +z.uid}))}
  >
    <Dropdown menu={{items: fileOptions}}>
      <Button type="link" style={{paddingLeft: 0}} onClick={v => v.stopPropagation()}>Choose file</Button>
    </Dropdown>
  </Upload>
}