import {useEffect, useState} from "react";
import {useMutation} from "@apollo/client";
import { ExchangeTokenDocument } from "generated/graphql";
import {setToken, userVar} from "utilities/auth";
import {useNavigate} from "react-router-dom";
import {SetUserDetailsDialog} from "routes/Dialogs/SetUserDetailsDialog";

export function Callback() {
  const [exchangeToken] = useMutation(ExchangeTokenDocument);
  const navigate = useNavigate();
  const [userDialogOpen, setUserDialogOpen] = useState(false);

  const idToken = window.location.hash
    .split('&')
    .map(t => t.split('='))
    .filter(p => p[0] === "id_token")[0][1];

  useEffect(() => {
    exchangeToken({ variables: { input: { orcIdToken: idToken }} })
      .then(r => {
        if (!r.data?.exchangeToken) return;
        setToken(r.data?.exchangeToken.token);
        const user = userVar();
        if (user?.lastName) {
          navigate(-2);
        } else {
          setUserDialogOpen(true);
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken]);

  return <>
    <i>Processing login...</i>
    <SetUserDetailsDialog open={userDialogOpen} onClose={() => navigate(-2)} />
  </>;
}
