import {useMutation} from "@apollo/client";
import {RefereeRequestStatus, UpdateRefereeRequestDocument} from "generated/graphql";

export const useUpdateRefereeRequest = () => {
  const [mutate] = useMutation(UpdateRefereeRequestDocument);

  const setStatus = (refereeRequestId: number, status: RefereeRequestStatus, comment?: string) =>
    mutate({ variables: { input: { refereeRequestId, status, comment }} });

  const setHideReport = (refereeRequestId: number, hideReport: boolean) =>
    mutate({ variables: { input: { refereeRequestId, hideReport } } });

  const extend = (refereeRequestId: number, duration: number) =>
    mutate({ variables: { input: { refereeRequestId, duration }} });

  return { setStatus, extend, setHideReport };
}