import {ArticleWithVersionFragment} from "generated/graphql";
import {Export} from "components/Table/DataTable";

export const TexExport = (fileName: string, data: ArticleWithVersionFragment[]) : Export => ({
  fileName: fileName + '.tex',
  mimeType: "text/tex",
  title: "TeX",
  render: () => {
    const entries = data.map(d => `
        \\CM{${d.number}}
        \\auth{${d.authors.map(a => a.displayName).join(", ")}}
        \\title{${d.title}}
        \\pp{${d.lastVersion.pageCount}}
        \\decision{}
      `);
    const lines = [
      `
      \\documentclass[11pt]{article}

      \\usepackage[utf8]{inputenc}
      \\usepackage{a4wide}
      \\usepackage{CMreport}
      
      \\begin{document}
      `,
      ...entries,
      `
      \\end{document}`
    ];
    return lines.join('\n');
  }
})