import {DataTable} from "components/Table/DataTable";
import {useQuery} from "@apollo/client";
import {GetFormsDocument} from "generated/graphql";
import {DialogButton} from "components/Modal/DialogButton";
import {VerticalSpace} from "components/Layout/VerticalSpace";
import {EditFormDialog} from "routes/Editor/Dialogs/EditFormDialog";
import {formatValue} from "utilities/enumHelpers";
import {Link} from "react-router-dom";

export const FormList = () => {
  const { data, loading, refetch } = useQuery(GetFormsDocument);

  return <>
    { data && <div style={{ maxWidth: 900 }}>
        <DialogButton type='primary' dialog={props => <EditFormDialog {...props} onComplete={refetch} />}>Create new form</DialogButton>
        <VerticalSpace />
        <DataTable source={data.forms} columns={[
        {
          key: 'Title',
          value: t => t.title,
          render: t => <Link to={`/editor/form/${t.id}`}>{t.title}</Link>
        },
        {
          key: 'Type',
          value: t => formatValue(t.type),
          filter: true
        },
        {
          key: 'Action',
          header: <></>,
          render: t => <> <DialogButton dialog={props => <EditFormDialog {...props} target={t} />}>Edit</DialogButton> </>
        }
      ]} />
    </div>  }
    { loading && <i>Loading...</i> }
  </>
}