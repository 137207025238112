import {FormAnswerInput, FormChoice, FormQuestionInput, QuestionType} from "generated/graphql";

interface FormSubmission
{
    answers: FormAnswerInput[]
}

export type FormQuestion = FormQuestionInput & { choices: FormChoice[] };

interface Props {
    sub: FormSubmission | null | undefined
    question: FormQuestion
}

export const ViewAnswer = ({sub, question}: Props) => {
    const ans = sub?.answers.filter(a => a.questionId === question.id)[0];
    switch (question.type) {
        case QuestionType.Open: return <>{ans?.text}</>;
        case QuestionType.YesNo: return <>{ans?.boolean ? "Yes" : "No"}</>;
        case QuestionType.Choice: return <>{question.choices.filter(c => c.id === ans?.choiceId)[0]?.text}</>;
    }
    return <></>;
}