import {useLocation} from "react-router-dom";
import {useEffect} from "react";

export const Login = () => {
  const { search } = useLocation();
  const to = new URLSearchParams(search).get("to");

  useEffect(() => {
    window.location.href = `/${to}`;
  });

  return <></>
}