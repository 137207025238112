import {Select, SelectProps} from "antd";
import {useQuery} from "@apollo/client";
import {GetCountriesDocument} from "generated/graphql";

const { Option } = Select;

export const CountrySelect = (props: SelectProps) => {
  const { data } = useQuery(GetCountriesDocument);

  return <Select {...props} showSearch optionFilterProp="children">
    { data?.countries.map(o => <Option key={o.code}>{o.name}</Option>) }
  </Select>
}