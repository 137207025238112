import {useQuery} from "@apollo/client";
import {GetVolumesDocument} from "generated/graphql";
import {Link} from "react-router-dom";
import {Collapse} from "antd";
import dayjs from "dayjs";

export const VolumeList = () => {
  const volumes = useQuery(GetVolumesDocument);

  return <div>
    <h3><Link to={`volumes/${volumes.data?.volumes?.[0].id}`}>Online volumes</Link></h3>
      { volumes.data && <Collapse bordered={false}
                                  className="volume-list"
                                  defaultActiveKey={[volumes.data.volumes[0].id]}
                                  style={{ background: "transparent" }}
                                  items={volumes.data.volumes.map(v => ({
        key: v.id,
        className: "volume-list-item",
        label: <span className="link">Volume {v.id} ({v.year})</span>,
        children: <div style={{ marginLeft: "12px" }}>
          { v.issues.map(i => <div key={i.id}><Link to={`issues/${i.id}`}>Issue {i.issueNumber} ({dayjs(i.date).format("MMMM YYYY")})</Link></div>) }
        </div>,
        style: { border: 'none' }
      }))} /> }
  </div>
}