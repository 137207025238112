import {Form, Input, Modal} from "antd";
import {useState} from "react";
import {DialogProps} from "components/Modal/DialogProps";
import {required} from "utilities/formRules";
import {useEditArticleData} from "hooks/useEditArticleData";

interface Props {
  articleId: number
  title: string
}
export const EditTitleDialog = ({ open, onClose, articleId, title }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const editArticleData = useEditArticleData();

  const handleOk = async () => {
    const values = await form.validateFields();
    setLoading(true);
    await editArticleData({
      ...values,
      articleId
    });
    setLoading(false);
    onClose();
  };

  return <Modal title="Edit title"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical" initialValues={{ title }}>
      <Form.Item name="title" label="Title" rules={required}>
        <Input />
      </Form.Item>
    </Form>
  </Modal>
}