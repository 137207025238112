import {DialogProps} from "components/Modal/DialogProps";
import {Button, Descriptions, Modal} from "antd";
import {GetFormSubmissionDocument} from "generated/graphql";
import {useQuery} from "@apollo/client";
import {ViewAnswer} from "components/Layout/ViewAnswer";
import {useEffect} from "react";

interface Props {
  submissionId: number
}

export const ViewFormDialog = ({ open, onClose, submissionId }: DialogProps & Props) => {
  const { data, refetch } = useQuery(GetFormSubmissionDocument, {
    variables: { id: submissionId },
    skip: !open
  });

  useEffect(() => {
    refetch();
  }, [refetch, open]);

  const sub = data?.formSubmission;

  return <Modal title={sub?.form.title}
                open={open}
                footer={<Button onClick={onClose}>Close</Button>}
                onCancel={onClose}
                width="800px"
  >
    { sub && <Descriptions bordered column={1}>
      { sub.form.questions.map(q => <Descriptions.Item key={q.id} label={q.identifier}>
          <ViewAnswer sub={sub} question={q} />
        </Descriptions.Item>)}
    </Descriptions> }
  </Modal>
}