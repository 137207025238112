import {Button, Form, Input, Space} from "antd";
import {useState} from "react";
import {useMutation} from "@apollo/client";
import {CreateAccountDocument, SendValidationDocument, CheckValidationDocument, ResetPasswordDocument} from "generated/graphql";
import {password, required} from "utilities/formRules";
import {setToken} from "utilities/auth";
import {VerticalSpace} from "components/Layout/VerticalSpace";

interface Props {
  onCancel: () => void;
  isReset: boolean;
}

export const RegisterControl = ({ onCancel, isReset }: Props) => {
  const [email, setEmail] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);

  const [sendValidation] = useMutation(SendValidationDocument);
  const [checkValidation] = useMutation(CheckValidationDocument);
  const [createAccount] = useMutation(CreateAccountDocument);
  const [resetPassword] = useMutation(ResetPasswordDocument);

  return <>{ email == null &&
    <Form layout="vertical" requiredMark={false}
          onFinish={async values => {
      const res = await sendValidation({ variables: { input: {
        mailAddress: values.email,
        newAccount: !isReset
      }} })
      if (!res.data?.sendValidation.success) {
        alert(isReset ? 'Email address not known' : 'Email address already registered');
      } else {
        setEmail(values.email);
      }
    }}>
      <Form.Item name="email" label="E-mail address"
                 rules={[{ required: true, message: 'Please enter your e-mail address' }]}>
        <Input type="email" />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={onCancel}>
              Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form> }

    { email && !code &&
    <Form layout="vertical" requiredMark={false}
          onFinish={async values => {
      const res = await checkValidation({ variables: { input: {
        mailAddress: email,
        code: values.code
      } } });
      if (!res.data?.checkValidation.success) {
        alert('Code does not match');
      } else {
        setCode(values['code']);
      }
    }}>
        <Form.Item>
          A validation code has been sent to {email}. Please enter the code below.
        </Form.Item>
        <Form.Item name="code" label="Validation code"
                   rules={[{ required: true, message: 'Please enter your validation code' }]}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
            <Button onClick={() => setEmail(null)}>
                Back
            </Button>
          </Space>
        </Form.Item>
    </Form> }

    { email != null && code != null && <>
      <Form layout="vertical" onFinish={async input => {
        const { password2, ...values } = input;
        if (values.password !== password2) {
          alert("Passwords do not match");
          return;
        }
        if (isReset) {
          const res = await resetPassword({
            variables: {
              input: {
                ...values,
                mailAddress: email,
                code
              }
            }
          });
          if (!res.data?.resetPassword.token) {
            alert('Failed to reset password');
            return;
          }
          setToken(res.data?.resetPassword.token);
        }
        else {
          const res = await createAccount({
            variables: {
              input: {
                ...values,
                mailAddress: email,
                code
              }
            }
          });
          if (!res.data?.createAccount.token) {
            alert('Failed to create account');
            return;
          }
          setToken(res.data?.createAccount.token);
        }
      }}>
        { isReset && <>Choose a new password.</> }
        { !isReset && <>Enter the details below to create a new account.</> }
          <VerticalSpace />
        { !isReset && <>
          <Form.Item name="firstName" label="First name" rules={required}>
              <Input />
          </Form.Item>
          <Form.Item name="lastName" label="Last name" rules={required}>
              <Input />
          </Form.Item>
          <Form.Item name="affiliation" label="Affiliation">
              <Input />
          </Form.Item>
        </> }
          <Form.Item name="password" label="Password" rules={password}>
              <Input type="password" />
          </Form.Item>
          <Form.Item name="password2" label="Confirm password" rules={password}>
              <Input type="password" />
          </Form.Item>
          <Form.Item>
              <Space>
                  <Button type="primary" htmlType="submit">
                      Submit
                  </Button>
                  <Button onClick={onCancel}>
                      Cancel
                  </Button>
              </Space>
          </Form.Item>
      </Form>
    </> }
  </>
}