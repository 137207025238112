import {DialogProps} from "components/Modal/DialogProps";
import {useState} from "react";
import {Form, Input, Modal} from "antd";
import {useEditUser} from "hooks/useEditUser";
import {email, required} from "utilities/formRules";
import {useReactiveVar} from "@apollo/client";
import {userVar} from "utilities/auth";

export const SetUserDetailsDialog = ({ open, onClose }: DialogProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const editUser = useEditUser();

  const user = useReactiveVar(userVar);

  const handleOk = async () => {
    const values = await form.validateFields();
    setLoading(true);
    await editUser({
      userId: user?.id,
      ...values
    });
    setLoading(false);
    onClose();
  };

  return <Modal title="Set user details"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                maskClosable={false}
                closeIcon={false}
                cancelButtonProps={{ style: { display: 'none' }}}
  >
    <Form form={form} layout="vertical">
      <Form.Item name="firstName" label="First name" rules={required} initialValue={user?.firstName}>
        <Input />
      </Form.Item>
      <Form.Item name="lastName" label="Last name" rules={required}>
        <Input />
      </Form.Item>
      <Form.Item name="affiliation" label="Affiliation">
        <Input />
      </Form.Item>
      <Form.Item name="mailAddress" label="E-mail address" rules={email}>
        <Input type='email' />
      </Form.Item>
    </Form>
  </Modal>
}