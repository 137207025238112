import {DialogProps} from "components/Modal/DialogProps";
import {useState} from "react";
import {Button, Form, Input, Modal, Select, Switch} from "antd";
import {required} from "utilities/formRules";
import {EnumSelect} from "components/Input/EnumSelect";
import {AccessLevel, EditTemplateInput, GetUsersDocument, TemplateType} from "generated/graphql";
import {useEditTemplate} from "hooks/useEditTemplate";
import TextArea from "antd/es/input/TextArea";
import {formatTemplateType} from "utilities/formatters";
import {useQuery} from "@apollo/client";

const { confirm } = Modal;

interface Props {
  template?: EditTemplateInput;
  onComplete?: (remove: boolean) => void;
}

export const EditTemplateDialog = ({ open, onClose, template, onComplete }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const editTemplate = useEditTemplate();
  const {data} = useQuery(GetUsersDocument, { variables: { level: AccessLevel.Editor  } });

  const handleOk = async () => {
    const values = await form.validateFields();
    console.log(values);
    setLoading(true);
    await editTemplate({ id: template?.id ?? 0, ...values });
    setLoading(false);
    onClose();
    onComplete?.(false);
  };

  return <Modal title="Edit template"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
                width="800px"
  >
    <Form form={form} layout="vertical" initialValues={template}>
      <Form.Item name="type" label="Type" rules={required}>
        <EnumSelect type={TemplateType} format={formatTemplateType} />
      </Form.Item>
      { data && <Form.Item name="userId" label="Editor">
          <Select options={data.users.map(s => ({ label: s.displayName, value: s.id }))} />
      </Form.Item> }
      <Form.Item name="name" label="Name" rules={required}>
        <Input />
      </Form.Item>
      <Form.Item name="subject" label="Subject" rules={required}>
        <Input />
      </Form.Item>
      <Form.Item name="body" label="Body" rules={required}>
        <TextArea rows={15} />
      </Form.Item>
      <Form.Item name="separatorBelow" label="Separator below" valuePropName="checked">
        <Switch />
      </Form.Item>
      { template && <Button style={{ float: 'left' }} type='link' onClick={() => confirm({
        title: 'Delete template',
        content: `Are you sure you want to delete ${template?.name}?`,
        onOk: async () => {
          const { __typename: _, ...values } = template as any;
          await editTemplate({ ...values, isDeleted: true } );
          onClose();
          onComplete?.(true);
        }
      })}>Delete</Button> }
    </Form>
  </Modal>
}