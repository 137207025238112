import {Button, ButtonProps} from "antd";
import {PropsWithChildren, useState} from "react";
import {DialogProps} from "components/Modal/DialogProps";

interface Props {
  dialog: (props: DialogProps) => JSX.Element;
}

export function DialogButton({ dialog, children, ...buttonProps }: PropsWithChildren<Props> & ButtonProps) {
  const [open, setOpen] = useState(false);

  const { type, ...props } = buttonProps;

  const res = dialog({
    open,
    onClose: () => setOpen(false)
  });

  return <>
    <Button type={type ?? 'link'} {...props} onClick={() => setOpen(true)}>{children}</Button>
    {res}
  </>
}