import {Button} from "antd";
import {FlagFilled, FlagOutlined} from "@ant-design/icons";
import {useState} from "react";
import {useEditArticleData} from "hooks/useEditArticleData";

interface Props {
  article: { isFlagged: boolean, id: number }
  inline?: boolean
}

export const FlagButton = ({ article, inline }: Props) => {
  const [loading, setLoading] = useState(false);
  const editArticleData = useEditArticleData();

  const toggleStatus = async () => {
    setLoading(true);

    await editArticleData({ articleId: article.id, isFlagged: !article.isFlagged });

    setLoading(false);
  }

  return <div style={inline ? { display: "inline-block" } : { textAlign: "center" }}>
    <Button type="link"
            disabled={loading}
            style={{ padding: 0, opacity: article.isFlagged ? 1 : 0.3 }}
            onClick={toggleStatus}
    >
      { article.isFlagged && <FlagFilled /> }
      { !article.isFlagged && <FlagOutlined /> }
    </Button>
  </div>
}