import {PropsWithChildren} from "react";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {graphqlUri} from "env";
import {createUploadLink} from "apollo-upload-client";
import {setContext} from "@apollo/client/link/context";
import dayjs from "dayjs";
import {clearToken} from "utilities/auth";

if (!graphqlUri) {
  throw new Error("GraphQL uri not set");
}

const httpLink = createUploadLink({ uri: graphqlUri });

const authLink = setContext((_, { headers }) => {
  const expiry = localStorage.getItem('tokenExpiry');
  if (expiry && dayjs.unix(+expiry) < dayjs()) clearToken();
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "GraphQL-preflight": "1",
    }
  }
});

export const GraphqlProvider = ({children}: PropsWithChildren<{}>) => {
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}