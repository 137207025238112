import {Button, Modal, ModalProps} from "antd"
import {MinusOutlined} from "@ant-design/icons";
import styles from "./MinimizeModal.module.css";
import {useState} from "react";

export const MinimizeModal = ({ children, open, ...props }: ModalProps) => {
  const [isMinimized, setIsMinimized] = useState(false);

  return <>
    <Modal {...props} open={!isMinimized && open}>
      <Button type="text" className={styles.minus} onClick={() => setIsMinimized(true)}><MinusOutlined /></Button>
      {children}
    </Modal>
    { isMinimized &&
    <div className={styles.container}>
      <div className={styles.minimized} style={{ width: props.width }} onClick={() => setIsMinimized(false)}>
        {props.title}
      </div>
    </div>
    }
  </>
}