import {Button} from "antd";
import {CSSProperties, useState} from "react";
import {PickedUser, UserPicker} from "components/Picker/UserPicker";

interface Props {
  onChange?: (o: PickedUser) => void
  value?: PickedUser
  disabled?: boolean
  buttonStyle?: CSSProperties
  buttonType?: "link" | "primary" | "default"
}

export const UserPickElement = ({ value, onChange, disabled = false, buttonType, buttonStyle }: Props) => {
  const [open, setOpen] = useState(false);

  return <>
    <Button type={buttonType ?? (!value ? 'primary' : 'default')}  style={buttonStyle}
            disabled={disabled} onClick={() => setOpen(true)}>
      { value && value.displayName }{ !value && <>Choose...</> }
    </Button>
    <UserPicker
            onPick={o => { onChange && onChange(o); setOpen(false); }}
            onClose={() => setOpen(false)}
            open={open} />
  </>
}