import {Column} from "components/Table/Column";
import {Button, Dropdown, Table, TablePaginationConfig} from "antd";
import {useMemo} from "react";
import {ColumnType} from "antd/es/table";
import {formatDate, formatDateTime} from "utilities/formatters";
import {downloadFile} from "utilities/downloadFile";

interface Props<T> {
  source: T[];
  columns: Column<T>[];
  summary?: () => JSX.Element;
  rowKey?: string;
  exports?: Export[];
  pagination?: false | TablePaginationConfig;
}

export interface Export {
  fileName: string;
  title: string;
  mimeType: 'text/csv' | 'text/tex';
  render?: () => string;
}

export const CsvExport = (fileName: string): Export => ({
  fileName: fileName + ".csv",
  title: "Comma-separated",
  mimeType: "text/csv"
});

function generateCsv<T>(source: T[], columns: Column<T>[]) {
  const rows = [
    columns.map(c => `"${c.key}"`).join(','),
    ...source.map(t =>
      columns.map(c => '"' + (c.value?.(t) ?? '').toString() + '"').join(',')
    )
  ];
  return rows.join('\n');
}

function downloadExport(ex: Export) {
  const content = ex.render?.() ?? "";
  downloadFile(content, ex.fileName, 'text/csv');
}

export function DataTable<T extends object>({ source, columns, summary, rowKey = "id", exports = [], pagination = false }: Props<T>) {

  const mappedColumns = useMemo(() => columns.map<ColumnType<T>>(c => {
    const val = c.value ?? (_ => "");
    return {
      title: c.header ?? c.key,
      key: c.key,
      filters: c.filter ? Array.from(new Set(source.map(a => val(a)))).map(p => ({ text: p, value: p })) : undefined,
      onFilter: c.filter ? ((f,a) => val(a) === f) : undefined,
      render: c.render ??
        (c.type === "date" ? (a => formatDate(val(a)))
        : c.type === "dateTime" ? (a => formatDateTime(val(a)))
        : (a => val(a)?.toString())),
      width: c.width,
      sorter: c.sorter ?? c.value ? ((a,b) => typeof(val(a)) === "number" ? val(a) - val(b)
        : val(a)?.toString().localeCompare(val(b)?.toString())) : undefined
    };
  }), [columns, source]);

  return <>
    { !!exports?.length &&
      <Dropdown
          menu={{ items: exports.map(e => ({
            label: e.title,
            key: e.mimeType,
            onClick: () => downloadExport(e.render ? e : { ...e, render: () => generateCsv(source, columns) })
          })) }}>
          <Button type="link" style={{ float: "right" }}>Export</Button>
      </Dropdown>
    }
    <Table dataSource={source} rowKey={rowKey}
           columns={mappedColumns}
           pagination={pagination}
           summary={summary}
           bordered
    />
  </>
}