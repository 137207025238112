import {Button, Form, Modal, Switch, Upload} from "antd";
import {useState} from "react";
import {DialogProps} from "components/Modal/DialogProps";
import TextArea from "antd/es/input/TextArea";
import {useSubmitRefereeReport} from "hooks/useSubmitRefereeReport";
import {useAuth} from "hooks/useAuth";
import {UserAction} from "generated/graphql";

interface Props {
  refereeRequestId?: number
  referee?: string;
}

export const RefereeReportDialog = ({ open, onClose, refereeRequestId, referee }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { can } = useAuth();
  const submitReport = useSubmitRefereeReport();

  const handleOk = async () => {
    const { file, comment, commentToFile } = await form.validateFields();
    if (!file?.file && !comment) {
      alert("Enter a comment or upload a file");
      return;
    }
    if (commentToFile && file?.file) {
      alert("Cannot convert comment to file when uploading a file");
      return;
    }
    setLoading(true);
    await submitReport({
      comment: comment,
      file: file?.file,
      commentToFile,
      refereeRequestId: refereeRequestId ?? 0 // TODO: correct typing
    });
    setLoading(false);
    form.resetFields();
    onClose();
  };

  return <Modal title={referee ? `Submit referee report for ${referee}` : "Submit referee report"}
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical">
      <Form.Item name="file" label="File" valuePropName="file">
        <Upload accept=".pdf,.txt" maxCount={1} beforeUpload={() => false}>
          <Button type="primary">Choose file</Button>
        </Upload>
      </Form.Item>
      <Form.Item name="comment" label="Comment">
        <TextArea rows={5} />
      </Form.Item>
      { can(UserAction.ManageArticles) && <Form.Item name="commentToFile" label="Save comment as file">
        <Switch />
      </Form.Item> }
    </Form>
  </Modal>
}