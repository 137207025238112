import {Button, Input, Modal} from "antd";
import {DialogProps} from "components/Modal/DialogProps";
import {PropsWithChildren, useState} from "react";
import {DocumentNode, useQuery} from "@apollo/client";
import styles from "./Picker.module.css";

interface Props {
  onPick: (pick: any) => void
  queryDocument: DocumentNode
  render: (obj: any) => JSX.Element | string
  placeholder: string
}

export const Picker = ({ open, onClose, onPick, queryDocument, render, placeholder, children }: DialogProps & PropsWithChildren<Props>) => {
  const [query, setQuery] = useState('');

  const { data, loading } = useQuery(queryDocument, { variables: { query }, skip: query.length < 3 });
  const result = data ? data[Object.keys(data)[0]] : undefined;

  return <Modal title="Find user"
                open={open}
                footer={null}
                onCancel={onClose}
  >
    <Input onChange={t => setQuery(t.currentTarget.value)} placeholder={placeholder} />
    <div className={styles.results}>
      { result && result.map((u: any) => <div key={u.id}>
        <Button type="link" onClick={() => onPick(u)}>{render(u)}</Button>
      </div>) }
      { loading && <i>Loading...</i> }
    </div>
    {children}
  </Modal>
}