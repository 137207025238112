import {useState} from "react";
import {RefereeRequestStatus} from "generated/graphql";
import {useUpdateRefereeRequest} from "hooks/useUpdateRefereeRequest";

export const useConfirmRefereeRequest = () => {
  const [isConfirming, setIsConfirming] = useState(false);

  const { setStatus } = useUpdateRefereeRequest();

  const confirm = async (req: { id: number } | undefined, accept: boolean, comment?: string) => {
    if (!req) return;
    setIsConfirming(true);
    await setStatus(req?.id, accept ? RefereeRequestStatus.Refereeing : RefereeRequestStatus.Closed, comment);
    setIsConfirming(false);
  }

  return { confirm, isConfirming };
}