import {DialogProps} from "components/Modal/DialogProps";
import {useState} from "react";
import {Form, Modal} from "antd";
import {required} from "utilities/formRules";
import {FormAnswer, GetFormSubmissionDocument} from "generated/graphql";
import {useSaveFormAnswers} from "hooks/useSaveFormAnswers";
import {useQuery} from "@apollo/client";
import {FormInputControl} from "components/Input/FormInputControl";

interface Props {
  submissionId: number
  onComplete?: () => void
}

const submissionToValues = (answers: FormAnswer[]) => {
  return Object.fromEntries(answers.map(a => [a.questionId, a]));
}

const toAnswerInput = ({ questionId, text, choiceId, boolean } : any) =>
  ({ questionId, text, choiceId, boolean });

export const FormDialog = ({ open, onClose, submissionId, onComplete }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { data } = useQuery(GetFormSubmissionDocument, { variables: { id: submissionId }, skip: !submissionId });

  const sub = data?.formSubmission;
  const save = useSaveFormAnswers();

  const handleOk = async (submit: boolean) => {
    const values = submit ? await form.validateFields() : await form.getFieldsValue();
    setLoading(true);
    await save({ submissionId, submit, answers: Object.values(values).filter(v => v).map(toAnswerInput) });
    setLoading(false);
    onClose();
    if (submit) onComplete?.();
  };

  return <Modal title={sub?.form.title}
                open={open}
                onOk={() => handleOk(true)}
                cancelText="Save and close"
                okText="Submit"
                confirmLoading={loading}
                onCancel={() => handleOk(false)}
                width="600px"
  >
    { sub && <Form form={form} layout="vertical" initialValues={submissionToValues(sub.answers)}>
        <p style={{ whiteSpace: "pre-wrap" }}>{ sub.form.introduction }</p>
      { sub.form.questions.map(q => <Form.Item key={q.id} name={q.id} label={q.text} rules={q.isRequired ? required : []}>
        <FormInputControl question={q} />
      </Form.Item> )}
    </Form> }
  </Modal>
}