import {Select, SelectProps} from "antd";
import {getEntries} from "utilities/enumHelpers";
import {useMemo} from "react";

const { Option } = Select;

interface Props {
  type: object
  format?: (s: any) => string | undefined
}

export const EnumSelect = ({ type, format, ...props }: Props & SelectProps) => {
  const options = useMemo(() => getEntries(type), [type]);

  return <Select {...props}>
    { options.map(o => <Option key={o.entry}>{format?.(o.entry) ?? o.label}</Option>) }
  </Select>
}