import {DataTable} from "components/Table/DataTable";
import {useQuery} from "@apollo/client";
import {GetUsersDocument, UserAction} from "generated/graphql";
import {formatDate} from "utilities/formatters";
import {formatValue} from "utilities/enumHelpers";
import {Link} from "react-router-dom";
import {Button, Dropdown, Input, Modal} from "antd";
import {useDeleteUser} from "hooks/useDeleteUser";
import {useState} from "react";
import {UserPicker} from "components/Picker/UserPicker";
import {SearchOutlined} from "@ant-design/icons";
import {useEditUser} from "hooks/useEditUser";
import {useAuth} from "hooks/useAuth";

const { confirm } = Modal;

export const UserList = () => {
  const { data, loading, refetch } = useQuery(GetUsersDocument);

  const [query, setQuery] = useState("");
  const [mergeId, setMergeId] = useState<number | null>(null);

  const deleteUser = useDeleteUser();
  const editUser = useEditUser();

  const { can } = useAuth();

  return <>
    { data && <>
        <div style={{marginBottom: 15, maxWidth: 400}}>
            <Input size="large" placeholder="Search by name or e-mail address"
                   prefix={<SearchOutlined/>}
                   onInput={e => setQuery(e.currentTarget.value)} value={query} />
        </div>
        <DataTable source={data.users.filter(u => u.displayName.toLowerCase().includes(query.toLowerCase()) || u.mailAddress?.toLowerCase().includes(query.toLowerCase()))}
                   pagination={{ defaultPageSize: 50 }} columns={[
        {
          key: 'Name',
          value: t => t.lastName,
          render: t => <>
            <Link className={t.isDisabled ? "link-disabled" : ""} to={t.id.toString()}>{t.displayName}</Link>
            { t.isDisabled && <span className="text-disabled"> (disabled)</span> }
          </>
        },
        {
          key: 'Email',
          value: t => t.mailAddress
        },
        {
          key: 'Last login',
          value: t => formatDate(t.lastLogin)
        },
        {
          key: 'Rights',
          value: t => formatValue(t.accessLevel)
        },
        {
          key: '',
          render: t => <>{ can(UserAction.ManageUsers) && <Dropdown menu={{ items: [
              {
                label: 'Delete user',
                key: 'delete',
                onClick: () => confirm({
                  title: `Delete ${t.displayName}`,
                  content: `Are you sure you want to delete this user? This cannot be undone.`,
                  onOk: async () => {
                    const res = await deleteUser({ userId: t.id });
                    if (!res.data?.deleteUser.success) {
                      alert('User has linked articles or referee requests and cannot be deleted');
                    }
                    else {
                      await refetch();
                    }
                  }
                })
              },
              {
                label: 'Merge into...',
                key: 'merge',
                onClick: () => setMergeId(t.id)
              },
              {
                label: t.isDisabled ? "Enable user" : "Disable user",
                key: 'enable',
                onClick: () => editUser({ userId: t.id, isDisabled: !t.isDisabled })
              }
            ] }}>
            <Button type="link">Actions</Button>
          </Dropdown> }</>
        }
      ]} />
        <UserPicker open={!!mergeId} onClose={() => setMergeId(null)} onPick={u => {
          deleteUser({
            userId: mergeId!,
            mergeIntoId: u.id
          }).then(() => refetch());
          setMergeId(null);
        }} />
    </> }
    { loading && <i>Loading...</i> }
  </>
}