import {useQuery} from "@apollo/client";
import {GetPublishedIssueDocument} from "generated/graphql";
import {useParams} from "react-router-dom";
import dayjs from "dayjs";
import Latex from "react-latex-next";
import 'katex/dist/katex.min.css';

export const Issue = () => {
  const { issueId: issueIdAsString } = useParams();
  const issueId = +(issueIdAsString ?? "");

  const { data } = useQuery(GetPublishedIssueDocument, { variables: { id: issueId } });

  return <>
    <h2>Volume {data?.issue.volume}, issue {data?.issue.issueNumber} ({dayjs(data?.issue.date).format("MMMM YYYY")})</h2>
    <div className="article-list">
      {data?.issue.publishedArticles.map(a => <div key={a.id}>
          {a.authors}
          <div className="pages">{a.startPage}-{a.endPage}</div>
          <a href={a.url}>{a.title.split("\\n").map(l => <div key={l}><Latex>{l}</Latex></div>)}</a>
        </div>)}
    </div>
  </>
}