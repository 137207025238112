import {useQuery} from "@apollo/client";
import {GetStatisticsDocument} from "generated/graphql";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {Radio} from "antd";
import {useState} from "react";

const colours = [
  "#8884d8",
  "#8ad884",
  "#d8bb84",
  "#d88484"];

const coordinateGenerator = (props: { xAxis: any }) => {
  const years = props.xAxis.domain.filter((d: string) => d.endsWith("/01"));
  return years.map((x: string) => props.xAxis.scale(x));
};

export const Statistics = () => {
  const [years, setYears] = useState(2);
  const { data } = useQuery(GetStatisticsDocument, { variables: { years } });

  return <>
    { data && <div style={{maxWidth: "1050px"}}>
        <Radio.Group value={years} onChange={v => setYears(v.target.value)}>
            <Radio.Button value={2}>2 years</Radio.Button>
            <Radio.Button value={3}>3 years</Radio.Button>
            <Radio.Button value={5}>5 years</Radio.Button>
            <Radio.Button value={10}>10 years</Radio.Button>
        </Radio.Group>

        <h2>Submissions per month</h2>
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data?.statistics.submissionsPerMonth}>
                <XAxis dataKey="month"/>
                <YAxis/>
                <CartesianGrid strokeDasharray="3 3" verticalCoordinatesGenerator={coordinateGenerator}/>
                <Tooltip/>
                <Bar name="Submissions" dataKey="value" fill={colours[0]}/>
            </BarChart>
        </ResponsiveContainer>

        <h2>Average number of submissions in status per month</h2>
        <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data?.statistics.statusPerMonth}>
                <XAxis dataKey="month"/>
                <YAxis/>
                <Tooltip/>
                <CartesianGrid strokeDasharray="3 3" verticalCoordinatesGenerator={coordinateGenerator}/>
                <Line dataKey="atReferee" name="At referee" stroke={colours[0]}/>
                <Line dataKey="atQuickOpinion" name="At quick opinion" stroke={colours[1]}/>
                <Line dataKey="majorRevision" name="Major revision" stroke={colours[2]}/>
                <Line dataKey="requestSent" name="Request sent" stroke={colours[3]}/>
                <Legend/>
            </LineChart>
        </ResponsiveContainer>

        <h2>Publication delay</h2>
        <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data?.statistics.publicationDelays}>
                <XAxis dataKey="month"/>
                <YAxis/>
                <Tooltip/>
                <CartesianGrid strokeDasharray="3 3" verticalCoordinatesGenerator={coordinateGenerator}/>
                <Line dataKey="delay" name="Delay in months" stroke={colours[0]}/>
                <Line dataKey="averageDelay" name="Moving average" stroke={colours[1]}/>
                <Legend/>
            </LineChart>
        </ResponsiveContainer>
    </div>
    }
  </>
}