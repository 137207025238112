import {Button, Form, Modal, Upload} from "antd";
import {useState} from "react";
import {DialogProps} from "components/Modal/DialogProps";
import {required} from "utilities/formRules";
import {useAddArticleEvent} from "hooks/useAddArticleEvent";
import TextArea from "antd/es/input/TextArea";

interface Props {
  articleId: number
}

export const AddCommentDialog = ({ open, onClose, articleId }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const addEvent = useAddArticleEvent();

  const handleOk = async () => {
    const { file, ...values } = await form.validateFields();
    setLoading(true);
    await addEvent({
      ...values,
      file: file?.file,
      articleId
    });
    setLoading(false);
    onClose();
    form.resetFields();
  };

  return <Modal title="Add comment"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical">
      <Form.Item name="comment" label="Comment" rules={required}>
        <TextArea rows={5} />
      </Form.Item>
      <Form.Item name="file" label="File" valuePropName="file">
        <Upload accept=".pdf,.txt" maxCount={1} beforeUpload={f => false}>
          <Button type="primary">Choose file</Button>
        </Upload>
      </Form.Item>
    </Form>
  </Modal>
}