import {Link, useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {FindArticlesDocument, GetIssueDocument} from "generated/graphql";
import {DataTable} from "components/Table/DataTable";
import {DialogButton} from "components/Modal/DialogButton";
import {VerticalSpace} from "components/Layout/VerticalSpace";
import {Picker} from "components/Picker/Picker";
import {Button, Modal, Space} from "antd";
import {useAddArticleToIssue} from "hooks/useAddArticleToIssue";
import {useState} from "react";
import {useEditIssue} from "hooks/useEditIssue";
import dayjs from "dayjs";
import {formatDate} from "utilities/formatters";
import {useJournal} from "hooks/useJournal";
import {downloadFile} from "utilities/downloadFile";

const { confirm } = Modal;

export const IssueDetail = () => {
  const { issueId } = useParams();
  const [processingAdd, setProcessingAdd] = useState(false);

  const { data, loading, refetch } = useQuery(GetIssueDocument, { variables: { id: +(issueId ?? '0') } });
  const issue = data?.issue;
  const addArticleToIssue = useAddArticleToIssue();
  const editIssue = useEditIssue();

  const addArticle = async (article: { id: number }) => {
    if (!issue) return;
    setProcessingAdd(true);
    await addArticleToIssue({ articleId: article.id, issueId: issue?.id })
    await refetch();
    setProcessingAdd(false);
  }

  const publish = () => {
    if (!issue) return;
    confirm({
      title: 'Are you sure you want to publish this issue?',
      content: 'You will no longer be able to make changes to the issue.',
      onOk: async () => {
        await editIssue({ id: issue.id, datePublished: dayjs() });
        await refetch();
      }
    })
  }

  const journal = useJournal();

  return <>
    { loading && <i>Loading...</i> }
    { issue && <>
      { !issue.datePublished && <Space>
          <DialogButton type="primary" loading={processingAdd} dialog={props =>
            <Picker {...props}
                    render={a => `${journal.code} ${a.number}: ${a.title}`}
                    placeholder="Search by name or number"
                    queryDocument={FindArticlesDocument}
                    onPick={a => { addArticle(a); props.onClose(); }} />}>Add article</DialogButton>
          <Button type="primary" onClick={publish}>Publish issue</Button>
        </Space> }
      { issue.datePublished && <>Issue published {formatDate(issue.datePublished)} </> }
        <VerticalSpace />
        <DataTable source={issue.articles} columns={[
          {
            key: 'Manuscript',
            value: a => `${journal.code} ${a.number}`
          },
          {
            key: 'Number',
            value: a => a.issueIndex
          },
          {
            key: 'Authors',
            value: a => a.authors.map(a => a.displayName).join(', ')
          },
          {
            key: 'Title',
            value: a => a.title
          },
          {
            key: 'Pages',
            value: a => a.lastVersion.pageCount
          }
          ]}
        />
        <div style={{ marginTop: "20px" }}>
          <Link to="preview"><Button type="link">Preview</Button></Link>
          { issue && <Button type="link"
              onClick={() => downloadFile(issue?.crossRefXml, `${journal.code}_${issue.volume}_${issue?.issueNumber}.xml`, "text/xml")}>
              Download Crossref xml
          </Button> }
        </div>
    </>}
  </>
}