import {DataTable} from "components/Table/DataTable";
import {useQuery} from "@apollo/client";
import { GetTemplatesDocument } from "generated/graphql";
import {formatTemplateType} from "utilities/formatters";
import {DialogButton} from "components/Modal/DialogButton";
import {EditTemplateDialog} from "routes/Editor/Dialogs/EditTemplateDialog";
import {VerticalSpace} from "components/Layout/VerticalSpace";

export const TemplateList = () => {
  const { data, loading, refetch } = useQuery(GetTemplatesDocument);

  return <>
    { data && <>
        <DialogButton type='primary' dialog={props => <EditTemplateDialog {...props} onComplete={_ => refetch()} />}>Create new template</DialogButton>
        <VerticalSpace />
        <DataTable source={data.templates} columns={[
        {
          key: 'Type',
          value: t => formatTemplateType(t.type),
          filter: true
        },
        {
          key: 'Editor',
          value: t => t.user?.initials
        },
        {
          key: 'Name',
          value: t => t.name
        },
        {
          key: 'Subject',
          value: t => t.subject
        },{ key: 'sep', value: t => t.separatorBelow },
        {
          key: 'Action',
          header: <></>,
          render: t => <DialogButton dialog={props =>
            <EditTemplateDialog {...props} template={t} onComplete={d => d && refetch()} />}>
            Edit
          </DialogButton>
        }
      ]} />
    </>  }
    { loading && <i>Loading...</i> }
  </>
}