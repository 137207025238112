import {Form, Modal, Select} from "antd";
import {useState} from "react";
import {DialogProps} from "components/Modal/DialogProps";
import {required} from "utilities/formRules";
import {AccessLevel, EditorType, GetUsersDocument} from "generated/graphql";
import {useAssignEditor} from "hooks/useAssignEditor";
import {useQuery} from "@apollo/client";
import {Recipient} from "../../../components/Modal/MailDialog";

interface Props {
  articleId: number
  type: EditorType,
  userId?: number,
  onComplete?: (res?: Recipient) => void
}

export const AssignEditorDialog = ({ open, onClose, articleId, type, userId, onComplete }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const assignEditor = useAssignEditor();

  const { data } = useQuery(GetUsersDocument,
    { variables: { level: type === EditorType.Managing ? AccessLevel.Editor : AccessLevel.Board } });
  const handleOk = async () => {
    const values = await form.validateFields();
    setLoading(true);
    await assignEditor({
      articleId,
      type,
      ...values
    });
    setLoading(false);
    onClose();
    onComplete?.(data?.users.filter(u => u.id === values.userId)?.[0]);
  };

  return <Modal title={`Change ${type === EditorType.Managing ? 'managing' : 'handling'} editor`}
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical" initialValues={{ userId }}>
      { data && <Form.Item name="userId" label="Editor" rules={required}>
        <Select options={data.users.map(s => ({ label: s.displayName, value: s.id }))} />
      </Form.Item> }
    </Form>
  </Modal>
}