import {RefereeRequestStatus} from "generated/graphql";

export const formatValue = (v: string) => {
  const parts = v.toLowerCase().split('_');
  const res = parts.join(' ');
  return res[0].toUpperCase() + res.slice(1);
}

export const getEntries = (s: object) => Object.values(s).map(v => ({
  entry: v,
  label: formatValue(v)
}))

export const isClosed = (status: RefereeRequestStatus) => {
  return status === RefereeRequestStatus.Closed || status === RefereeRequestStatus.NotReceived
    || status === RefereeRequestStatus.NoReply;
}