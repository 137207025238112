import {UserAction} from "generated/graphql";
import {PropsWithChildren, useState} from "react";
import {useAuth} from "hooks/useAuth";
import {LoginDialog} from "components/Account/LoginDialog";

interface Props {
  action: UserAction
}

export const AuthGuard = ({ action, children }: PropsWithChildren<Props>) => {
  const [loginOpen, setLoginOpen] = useState(true);

  const { user, can } = useAuth();
  const isAuthorized = can(action);

  return <>
    { !isAuthorized && <>
      { (user || !loginOpen) && <i>You are not authorized to see this page</i> }
      { !user && <LoginDialog open={loginOpen} onClose={() => setLoginOpen(false)} /> }
    </>}
    { isAuthorized && children }
  </>
}