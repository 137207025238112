import {Link, useMatches} from "react-router-dom";
import {useEffect} from "react";

export const Breadcrumb = () => {
  const matches = useMatches();

  const format = (match: { handle: any, params: any } ) => {
    let t = match.handle?.title ?? "Main page";
    for (let param in match.params) {
      t = t.replace(`:${param}`, match.params[param]);
    }
    return t;
  }

  const filtered = matches.filter((m,i) => m.handle || i === 0);

  const last = format(filtered[filtered.length - 1]);

  useEffect(() => { document.title = last; }, [last]);

  return <div> { filtered.map((m,i) => <span key={m.id}>
    { i === filtered.length - 1 && <span>{format(m)}</span>}
    { i < filtered.length - 1 && <>
        <Link to={m.pathname}>{format(m)} </Link>
        <span style={{ fontSize: 11, paddingRight: 5, paddingLeft: 5, color: '#aaa' }}>►</span>
    </> }
  </span>) } </div>
}