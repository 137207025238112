import {DialogProps} from "components/Modal/DialogProps";
import {Form, Input, Modal} from "antd";
import {email, required} from "utilities/formRules";
import {Author} from "generated/graphql";
import {CountrySelect} from "components/Input/CountrySelect";
import {useEffect} from "react";

interface Props {
  onOk: (a: Author) => void;
  initial?: Partial<Author>;
}

export const EditAuthorDialog = ({ open, onClose, onOk, initial }: DialogProps & Props) => {
  const [form] = Form.useForm();

  const handleOk = async () => {
    const values = await form.validateFields();
    onOk(values);
    onClose();
  };

  useEffect(() => {
    if (open) {
      // we need this delay because Ant forms are odd...
      setTimeout(form.resetFields, 50);
    }
  }, [form, open]);

  return <Modal title="Add author"
                open={open}
                onOk={handleOk}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical" requiredMark={false} initialValues={initial}>
      <Form.Item name="firstName" label="First name" rules={required}>
        <Input />
      </Form.Item>
      <Form.Item name="lastName" label="Last name" rules={required}>
        <Input />
      </Form.Item>
      <Form.Item name="affiliation" label="Affiliation" rules={required}>
        <Input />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={email}>
        <Input />
      </Form.Item>
      <Form.Item name="countryCode" label="Country" rules={required}>
        <CountrySelect />
      </Form.Item>
    </Form>
  </Modal>
}