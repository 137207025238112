import {Form, Modal} from "antd";
import {useState} from "react";
import {DialogProps} from "components/Modal/DialogProps";
import {required} from "utilities/formRules";
import TextArea from "antd/es/input/TextArea";

interface Props {
  title: string
  text: string
  onOk: (comment: string) => Promise<void>;
}

export const TextInputDialog = ({ open, onClose, onOk, title, text }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleOk = async () => {
    const { comment } = await form.validateFields();
    setLoading(true);
    await onOk(comment);
    setLoading(false);
    onClose();
  };

  return <Modal title={title}
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical" requiredMark={false}>
      <Form.Item name="comment" label={text} rules={required}>
        <TextArea rows={5} />
      </Form.Item>
    </Form>
  </Modal>
}