import {Form, Modal} from "antd";
import {useState} from "react";
import {DialogProps} from "components/Modal/DialogProps";
import {required} from "utilities/formRules";
import {useEditUser} from "hooks/useEditUser";
import {EnumSelect} from "components/Input/EnumSelect";
import {AccessLevel} from "generated/graphql";

interface Props {
  user: { id: number, accessLevel: AccessLevel }
}

export const EditRoleDialog = ({ open, onClose, user }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const editUser = useEditUser();

  const handleOk = async () => {
    const values = await form.validateFields();
    setLoading(true);
    await editUser({
      userId: user.id,
      ...values
    });
    setLoading(false);
    onClose();
  };

  return <Modal title="Edit role"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
  >
    <Form form={form} layout="vertical" requiredMark={false} initialValues={user}>
      <Form.Item name="accessLevel" label="Role" rules={required}>
        <EnumSelect type={AccessLevel} />
      </Form.Item>
    </Form>
  </Modal>
}