import {DialogProps} from "components/Modal/DialogProps";
import {useState} from "react";
import {Form, Input, Modal} from "antd";
import {required} from "utilities/formRules";
import {EnumSelect} from "components/Input/EnumSelect";
import {FormInfoFragment, FormType} from "generated/graphql";
import TextArea from "antd/es/input/TextArea";
import {useEditForm} from "hooks/useEditForm";

interface Props {
  target?: FormInfoFragment
  onComplete?: () => void
}

export const EditFormDialog = ({ open, onClose, target, onComplete }: DialogProps & Props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const editForm = useEditForm();

  const handleOk = async () => {
    const values = await form.validateFields();
    setLoading(true);
    await editForm({ id: target?.id ?? 0, ...values });
    setLoading(false);
    onClose();
    onComplete?.();
  };

  return <Modal title="Edit form"
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
                width="800px"
  >
    <Form form={form} layout="vertical" initialValues={target}>
      <Form.Item name="type" label="Type" rules={required}>
        <EnumSelect type={FormType} />
      </Form.Item>
      <Form.Item name="title" label="Title" rules={required}>
        <Input />
      </Form.Item>
      <Form.Item name="introduction" label="Introduction">
        <TextArea rows={5} />
      </Form.Item>
    </Form>
  </Modal>
}